import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const ctx = document.getElementById("cost");

const isHTMLCanvasElement = (
  element: HTMLElement | null,
): element is HTMLCanvasElement => {
  return element?.tagName == "CANVAS";
};

if (isHTMLCanvasElement(ctx)) {
  new Chart(ctx, {
    type: "bar",
    data: {
      labels: ["Current Monthly Costs", "With Recommended Plan"],
      datasets: [
        {
          label: "Cost / £",
          data: [ctx.dataset["before"], ctx.dataset["after"]],
          borderWidth: 1,
          datalabels: {
            anchor: "end",
            align: "top",
          },
          backgroundColor: ["rgba(238,180,22,0.24)", "rgba(101,241,46,0.24)"],
          borderColor: ["rgb(220,144,67)", "rgb(60,182,34)"],
        },
      ],
    },
    options: {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: ["grey", "green"],
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: function (value: number) {
            return new Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: "GBP",
            }).format(value);
          },
          font: {
            size: 20,
          },
        },
      },
    },
    plugins: [ChartDataLabels],
  });
}
