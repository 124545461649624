import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  isFixed: boolean;
  navBarElement: Element | null;
  isElementAboveViewport: (element: Element) => boolean;
}

export default (): AlpineComponent<State> => ({
  isFixed: false,
  navBarElement: null,

  init() {
    this.navBarElement = document.getElementById("targetElement") as Element;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isFixed = false;
        } else {
          this.isFixed = this.navBarElement
            ? this.isElementAboveViewport(this.navBarElement)
            : false;
        }
      });
    });

    observer.observe(this.navBarElement);
  },

  isElementAboveViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.bottom < 0;
  },
});
