import { AlpineComponent } from "alpinejs";

interface Option {
  label: string;
  value: string;
}

interface State extends Record<string | symbol, unknown> {
  options: Option[];
}

const parse = (data: unknown): Option[] | undefined => {
  if (!Array.isArray(data)) {
    return undefined;
  }

  const options = [];

  for (const element of data) {
    if (!Array.isArray(element)) {
      return undefined;
    }

    if (element.length !== 2) {
      return undefined;
    }

    const [value, label] = element as unknown[];

    if (typeof value !== "string") {
      return undefined;
    }

    if (typeof label !== "string") {
      return undefined;
    }

    options.push({ label, value });
  }

  return options;
};

const swap = (array: unknown[], i: number, j: number) => {
  if (i < 0 || i >= array.length || j < 0 || j >= array.length) {
    return;
  }

  const temporary = array[j];
  array[j] = array[i];
  array[i] = temporary;
};

export default (data: unknown): AlpineComponent<State> => ({
  options: parse(data) ?? [],
  down(value: unknown) {
    if (typeof value !== "string") {
      return;
    }

    const current = this.options.findIndex((x) => x.value === value);

    swap(this.options, current, current + 1);
  },
  up(value: unknown) {
    if (typeof value !== "string") {
      return;
    }

    const current = this.options.findIndex((x) => x.value === value);

    swap(this.options, current, current - 1);
  },
});
