import { AlpineComponent } from "alpinejs";
import L from "leaflet";

interface State extends Record<string | symbol, unknown> {
  latitude: number;
  longitude: number;
}

export default (
  latitude: unknown,
  longitude: unknown,
): AlpineComponent<State> => ({
  latitude: typeof latitude == "number" ? latitude : 51.5085,
  longitude: typeof longitude == "number" ? longitude : -0.1257,

  init() {
    const map = L.map("map").setView([this.latitude, this.longitude], 18);
    if (map) {
      const mapPane = map.getPane("mapPane");
      if (mapPane) {
        mapPane.style.zIndex = "0";
      }
    }

    L.tileLayer("https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);
    L.marker([this.latitude, this.longitude]).addTo(map);
  },
});
