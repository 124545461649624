import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  timeout: number;
  url: string;
}

export default (timeout: unknown, url: unknown): AlpineComponent<State> => ({
  timeout: typeof timeout == "number" ? timeout : 5000,
  url: typeof url == "string" ? url : window.location.href,

  init() {
    setTimeout(() => {
      window.location.href = this.url;
    }, this.timeout);
  },
});
