import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  isOpen: boolean;
  toggleModal(): void;
}

export default (isOpen: unknown): AlpineComponent<State> => ({
  isOpen: typeof isOpen == "boolean" ? isOpen : false,

  toggleModal() {
    this.isOpen = !this.isOpen;
  },
});
