import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  value: number;
  includePence: boolean;
}

export default (
  value: unknown,
  includePence: unknown,
): AlpineComponent<State> => ({
  value: typeof value == "number" ? value : 0,
  includePence: typeof includePence == "boolean" ? includePence : true,
  text() {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: this.includePence ? 2 : 0,
    }).format(this.value / 100);
  },
});
