import { AlpineComponent } from "alpinejs";
import { z } from "zod";

interface State extends Record<string | symbol, unknown> {
  slot: Duration | null;
}

const Duration = z.object({
  start: z.string(),
  length: z.number(),
});

type Duration = z.infer<typeof Duration>;

export default (): AlpineComponent<State> => {
  return {
    slot: null,
    date(): string {
      if (this.slot === null) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(this.slot.start));
    },
    length(): string {
      if (this.slot === null) {
        return "";
      }

      return hours(this.slot.length);
    },
    start(): string {
      if (this.slot === null) {
        return "";
      }

      return new Date(this.slot.start).toISOString();
    },
    time(): string {
      if (this.slot === null) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(this.slot.start));
    },
  };
};

const hours = (seconds: number): string => {
  const halves = Math.round(seconds / 60 / 30);

  const hours = Math.floor(halves / 2);

  if (halves === 0) {
    return "less than half an hour";
  }

  if (halves === 1) {
    return "half an hour";
  }

  if (halves === 2) {
    return "1 hour";
  }

  if (halves % 2 === 0) {
    return `${hours} hours`;
  }

  return `${hours} and a half hours`;
};
