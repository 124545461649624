import { FunctionalComponent } from "preact";

export const TableHeader: FunctionalComponent = ({ children }) => {
  return (
    <th className="bg-fn-black text-white px-2 py-3 text-start border border-gray-500 text-xs max-w-60">
      {children}
    </th>
  );
};

export const TableRow: FunctionalComponent = ({ children }) => {
  return (
    <tr className="table-row sm:odd:bg-fn-green/10 sm:even:bg-white">
      {children}
    </tr>
  );
};

export const TableCell: FunctionalComponent = ({ children }) => {
  return (
    <td className="truncate p-2 text-start text-xs flex sm:table-cell justify-between odd:bg-fn-green/10 sm:odd:bg-transparent first:!bg-fn-black first:sm:!bg-transparent first:text-white sm:first:text-fn-dark max-w-60">
      {children}
    </td>
  );
};
