import { AlpineComponent } from "alpinejs";
import { z } from "zod";

const Query = z.object({
  response: z.string(),
  url: z.string(),
});

type Query = z.infer<typeof Query>;

interface State extends Record<string | symbol, unknown> {
  answer: string;
  loading: boolean;
  check(url: string): Promise<void>;
}

export default (): AlpineComponent<State> => ({
  answer: "",
  loading: false,
  async check(url: string) {
    const response = await fetch(url);
    const parse = Query.safeParse(await response.json());

    if (!parse.success) {
      return;
    }

    this.answer = parse.data.response;

    if (this.answer === "") {
      setTimeout(() => this.check(url), 1000);
    } else {
      this.loading = false;
    }
  },
  async submit() {
    const form = this.$el;

    if (!(form instanceof HTMLFormElement)) {
      return;
    }

    const data = new FormData(form);

    this.loading = true;

    this.answer = "";

    const response = await fetch(form.action, {
      method: form.method,
      body: data,
    });

    const parse = Query.safeParse(await response.json());

    if (!parse.success) {
      return;
    }

    setTimeout(() => this.check(parse.data.url), 1000);
  },
});
