import { AlpineComponent } from "alpinejs";

enum Status {
  Ready,
  Loading,
  Failure,
  Error,
}

export interface State extends Record<string | symbol, unknown> {
  status: Status;
  content: string | null;
}

export default (): AlpineComponent<State> => ({
  status: Status.Ready,
  content: null,

  init() {
    this.content = this.$refs["content"]?.innerHTML ?? null;
  },

  submit(event: Event) {
    event.preventDefault();

    if (this.status === Status.Ready) {
      this.status = Status.Loading;
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const form = event.target.closest("form");
      if (!form) {
        return;
      }

      if (form instanceof HTMLFormElement) {
        const data = new FormData(form);
        const url = form.getAttribute("action");

        if (!url) {
          return;
        }

        const xhr = new XMLHttpRequest();

        xhr.onloadstart = () => {
          this.status = Status.Loading;
        };

        xhr.onload = () => {
          setTimeout(() => {
            this.content = xhr.responseText;

            setTimeout(() => {
              this.status = Status.Ready;
            }, 2000);
          }, 1000);
        };

        xhr.onerror = () => {
          setTimeout(() => {
            this.status = Status.Error;
          }, 1000);
        };

        xhr.open(form.method, url);
        xhr.send(data);
      }
    }
  },

  disabled() {
    return this.status !== Status.Ready;
  },
  ready() {
    return this.status === Status.Ready;
  },
  uploading() {
    return this.status === Status.Loading;
  },
  failure() {
    return this.status === Status.Failure;
  },
});
