import { AlpineComponent } from "alpinejs";
import Chart from "chart.js/auto";
import { z } from "zod";

const Worksheet = z.object({
  waterHeatingEnergyUsage219: z.number(),
  primary1SpaceHeatingEnergyUsage211: z.number(),
});

export type Worksheet = z.infer<typeof Worksheet>;

interface State extends Record<string | symbol, unknown> {
  load(): void;
}

export default (value: unknown): AlpineComponent<State> => {
  return {
    load() {
      if (Elm === undefined) {
        return;
      }

      if (Elm["Worker"] == undefined) {
        return;
      }

      if (typeof value !== "string") {
        return;
      }

      const app = Elm["Worker"].init({
        flags: {},
      });

      app.ports["exportedData"]?.subscribe?.((value) => {
        if (!(this.$refs["canvas"] instanceof HTMLCanvasElement)) {
          return;
        }

        const result = Worksheet.safeParse(value);

        if (!result.success) {
          return;
        }

        new Chart(this.$refs["canvas"], {
          type: "doughnut",
          data: {
            labels: ["Heating (kWh)", "Hot Water (kWh)"],
            datasets: [
              {
                label: "Annual Energy Usage",
                data: [
                  result.data.primary1SpaceHeatingEnergyUsage211,
                  result.data.waterHeatingEnergyUsage219,
                ],
                backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
                hoverOffset: 4,
              },
            ],
          },
        });
      });

      app.ports["extractedData"]?.send?.(atob(value));
    },
  };
};
