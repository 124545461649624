import { FunctionalComponent } from "preact";

import { Paper } from "@mui/material";

export const FormContainer: FunctionalComponent<{ [key: string]: unknown }> = ({
  children,
  ...props
}) => {
  return (
    <Paper elevation={3} className="shadow p-6 m-3" {...props}>
      {children}
    </Paper>
  );
};

export const FormInstructionsContainer: FunctionalComponent = ({
  children,
  ...props
}) => {
  return (
    <div className="col-span-3 bg-slate-900 p-6" {...props}>
      {children}
    </div>
  );
};
