import { Stripe, StripeElements } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  confirming: boolean;
  elements: StripeElements | undefined;
  errors: string[];
  stripe: Stripe | null;
  submitted: boolean;
  terms: boolean;
}

export default (
  key: unknown,
  clientSecret: unknown,
  return_url: unknown,
  cost: unknown,
): AlpineComponent<State> => ({
  confirming: false,
  elements: undefined,
  errors: [],
  stripe: null,
  submitted: false,
  terms: false,

  init() {
    if (typeof key !== "string") return;

    if (typeof clientSecret !== "string") return;

    if (typeof cost !== "string" || isNaN(parseInt(cost, 10))) return;

    void (async () => {
      this.stripe = await loadStripe(key);

      this.elements = this.stripe?.elements({ clientSecret });

      const payment = this.elements?.create("payment");

      const target = this.$refs["stripe"];

      if (target !== undefined) {
        payment?.mount(target);
      }
    })();
  },
  async submit(event: Event) {
    event.preventDefault();

    this.submitted = true;

    if (!this.terms) {
      return;
    }

    if (typeof return_url !== "string") return;

    if (this.elements === undefined) return;

    this.confirming = true;

    const response = await this.stripe?.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url,
      },
    });

    this.confirming = false;

    if (response !== undefined && typeof response.error.message == "string") {
      this.errors = [response.error.message];
    } else {
      this.errors = [];
    }
  },
  termsError() {
    return this.submitted && !this.terms;
  },
});
