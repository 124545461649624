import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  isVisible: boolean;
  toggleVisible(): void;
}

export default (isVisible: unknown): AlpineComponent<State> => ({
  isVisible: typeof isVisible == "boolean" ? isVisible : false,

  toggleVisible() {
    setTimeout(() => {
      this.isVisible = true;
    });
  },
});
