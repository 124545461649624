import { AlpineComponent } from "alpinejs";

export interface State extends Record<string | symbol, unknown> {
  drop(event: unknown): void;
}

export default (): AlpineComponent<State> => ({
  drop(event: unknown) {
    if (!(event instanceof DragEvent)) {
      return;
    }
    event.preventDefault();

    const input = this.$el.closest("form")?.querySelector("input[type='file']");

    if (!(input instanceof HTMLInputElement)) {
      return;
    }

    input.files = event.dataTransfer?.files ?? null;
    input.dispatchEvent(new Event("change"));
  },
});
