import { AlpineComponent } from "alpinejs";
import { z } from "zod";
import L from "leaflet";

const Location = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

const Project = z.object({
  type: z.literal("project"),
  latitude: z.number(),
  longitude: z.number(),
  address: z.string(),
  customer: z.string(),
  status: z.string(),
});

const Surveyor = z.object({
  type: z.literal("surveyor"),
  latitude: z.number(),
  longitude: z.number(),
  surveyor: z.string(),
});

const State = z.object({
  points: z.object({
    projects: z.array(Project),
    surveyors_approved: z.array(Surveyor),
    surveyors_enquired: z.array(Surveyor),
  }),
  init_coords: Location.extend({
    zoom: z.number().int(),
  }),
});

type State = z.infer<typeof State>;

export default (value: unknown): AlpineComponent<State> => {
  const result = State.safeParse(
    JSON.parse(typeof value === "string" ? value : ""),
  );

  if (!result.success) {
    throw new Error("Invalid initial state given to map.");
  }

  return {
    ...result.data,
    init() {
      const map = L.map(this.$el);
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      const projectsLayer = L.layerGroup().addTo(map);
      const surveyorsApprovedLayer = L.layerGroup();
      const surveyorsEnquiredLayer = L.layerGroup();

      this.points.projects.forEach((point) => {
        const marker = L.circleMarker([point.latitude, point.longitude], {
          radius: 6,
          stroke: false,
          fillOpacity: 0.8,
          color: "blue",
        });
        marker.bindPopup(
          `Lead <br>Address: ${point.address} <br>Customer: ${point.customer} <br>Status: ${point.status}`,
        );
        projectsLayer.addLayer(marker);
      });

      this.points.surveyors_approved.forEach((point) => {
        const marker = L.circleMarker([point.latitude, point.longitude], {
          radius: 6,
          stroke: false,
          fillOpacity: 0.8,
          color: "red",
        });
        marker.bindPopup(`Surveyor <br>Surveyor: ${point.surveyor}`);
        surveyorsApprovedLayer.addLayer(marker);
      });

      this.points.surveyors_enquired.forEach((point) => {
        const marker = L.circleMarker([point.latitude, point.longitude], {
          radius: 6,
          stroke: false,
          fillOpacity: 0.8,
          color: "red",
        });
        marker.bindPopup(`Surveyor <br>Surveyor: ${point.surveyor}`);
        surveyorsEnquiredLayer.addLayer(marker);
      });

      const overlayMaps = {
        Projects: projectsLayer,
        "Surveyors Approved": surveyorsApprovedLayer,
        "Surveyors Enquired": surveyorsEnquiredLayer,
      };

      L.control
        .layers({}, overlayMaps, { collapsed: false, position: "topleft" })
        .addTo(map);

      map.fitBounds(bounds(this));

      map.setView(
        [this.init_coords.latitude, this.init_coords.longitude],
        this.init_coords.zoom,
      );
    },
  };
};

const bounds = (state: State): L.LatLngBounds => {
  const all = [
    ...state.points.projects,
    ...state.points.surveyors_approved,
    ...state.points.surveyors_enquired,
  ].map((item) => ({ latitude: item.latitude, longitude: item.longitude }));

  const xs = all.map(({ longitude }) => longitude);
  const ys = all.map(({ latitude }) => latitude);

  const topLeft: L.LatLngExpression = [Math.max(...ys), Math.min(...xs)];
  const bottomRight: L.LatLngExpression = [Math.min(...ys), Math.max(...xs)];

  return L.latLngBounds(topLeft, bottomRight);
};
