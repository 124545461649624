import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  isOpen: boolean;
  toggleLightbox(): void;
  isPortrait: boolean;
}

export default (isOpen: unknown): AlpineComponent<State> => ({
  isOpen: typeof isOpen == "boolean" ? isOpen : false,
  isPortrait: window.innerWidth < window.innerHeight,

  init() {
    window.addEventListener("resize", () => {
      this.isPortrait = window.innerWidth < window.innerHeight;
    });
  },

  toggleLightbox() {
    this.isOpen = !this.isOpen;
  },
});
