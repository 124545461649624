import { AlpineComponent } from "alpinejs";

import { call } from "./fetch";

enum Status {
  Ready,
  Loading,
  Complete,
}

interface State extends Record<string | symbol, unknown> {
  status: Status;
}

export default (): AlpineComponent<State> => ({
  status: Status.Ready,
  async submit(event: Event) {
    event.preventDefault();

    if (event.target === null) {
      return;
    }

    if (this.status === Status.Ready) {
      this.status = Status.Loading;

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const form = event.target.closest("form");
      if (!form) {
        return;
      }

      const body = new FormData(form);

      const crsf = body.get("csrfmiddlewaretoken");
      if (typeof crsf !== "string") {
        return;
      }

      await call(form.action, {
        body,
        headers: {
          Accept: "application/json",
          "X-CSRFToken": crsf,
        },
        method: "PATCH",
      });

      this.status = Status.Ready;
    }
  },
  disabled() {
    return this.status !== Status.Ready;
  },
  ready() {
    return this.status === Status.Ready;
  },
});
