import { AlpineComponent } from "alpinejs";
import Chart from "chart.js/auto";
import { ChartData } from "chart.js";
import { z } from "zod";

const BuildingElement = z.discriminatedUnion("variant", [
  z.object({ variant: z.literal("GroundFloor"), area: z.number() }),
  z.object({ variant: z.literal("Window"), area: z.number() }),
  z.object({ variant: z.literal("ExternalWall"), area: z.number() }),
  z.object({ variant: z.literal("Roof"), area: z.number() }),
]);

const ValueByMonth = <T extends z.ZodTypeAny>(schema: T) =>
  z.object({
    jan: schema,
    feb: schema,
    mar: schema,
    apr: schema,
    may: schema,
    jun: schema,
    jul: schema,
    aug: schema,
    sep: schema,
    oct: schema,
    nov: schema,
    dec: schema,
  });

const Worksheet = z.object({
  buildingElements: z.array(BuildingElement),
  effectiveAirChangeRate25: ValueByMonth(z.number()),
  solidDoorFabricHeatLoss26: z.number(),
  semiGlazedDoorFabricHeatLoss26a: z.number(),
  windowFabricHeatLoss27: z.number(),
  roofWindowFabricHeatLoss27a: z.number(),
  basementFloorFabricHeatLoss28: z.number(),
  groundFloorFabricHeatLoss28a: z.number(),
  exposedFloorFabricHeatLoss28b: z.number(),
  basementWallFabricHeatLoss29: z.number(),
  externalWallFabricHeatLoss29a: z.number(),
  roofFabricHeatLoss30: z.number(),
  partyWallFabricHeatLoss32: z.number(),
  linearThermalBridges36: z.number(),
  pointThermalBridges36a: z.number(),
  ventilationHeatLoss38: ValueByMonth(z.number()),
  hotWaterUsage44: ValueByMonth(z.number()),
  waterHeatGains65: ValueByMonth(z.number()),
  totalInternalGains73: ValueByMonth(z.number()),
  solarGains83: ValueByMonth(z.number()),
  adjustedMeanInternalTemperature93: ValueByMonth(z.number()),
  afterSolarSpaceHeating98cm: ValueByMonth(z.number()),
  primary1SpaceHeatingEnergyUsage211m: ValueByMonth(z.number()),
  waterHeatingEnergyUsage219m: ValueByMonth(z.number()),
  sapRating258: z.number(),
});

export type Worksheet = z.infer<typeof Worksheet>;

const Input = z.object({
  sap: z.any(),
});

interface State extends Record<string | symbol, unknown> {
  cost: string | null;
  emissions: string | null;
  sapRating258: string | null;
  costReduction: string | null;
  emissionsReduction: string | null;
  sapRating258Improvement: string | null;
  floor: {
    area: number;
  } | null;
  wall: {
    area: number;
  } | null;
  opening: {
    count: number;
    area: number;
  } | null;
  size: ChartData<"bar">;
  insulation: ChartData<"bar">;
  bridges: ChartData<"bar">;
  ventilation: ChartData<"bar">;
  gains: ChartData<"line">;
  temperature: ChartData<"line">;
  space_heating: ChartData<"bar">;
  total_energy: ChartData<"bar">;
  primary: Worksheet | null;
  comparison: Worksheet | null;
}

export default (data: unknown, comparison: unknown): AlpineComponent<State> => {
  return {
    cost: null,
    emissions: null,
    sapRating258: null,
    costReduction: null,
    emissionsReduction: null,
    sapRating258Improvement: null,
    floor: null,
    wall: null,
    opening: null,
    size: {
      datasets: [],
    },
    insulation: {
      datasets: [],
    },
    bridges: {
      datasets: [],
    },
    ventilation: {
      datasets: [],
    },
    gains: {
      datasets: [],
    },
    temperature: {
      datasets: [],
    },
    space_heating: {
      datasets: [],
    },
    total_energy: {
      datasets: [],
    },
    primary: null,
    comparison: null,
    async init() {
      if (typeof data !== "string" || typeof comparison !== "string") {
        return;
      }

      if (!(this.$refs["size"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["insulation"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["bridges"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["ventilation"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["gains"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["temperature"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["space_heating"] instanceof HTMLCanvasElement)) {
        return;
      }

      if (!(this.$refs["total_energy"] instanceof HTMLCanvasElement)) {
        return;
      }

      Chart.defaults.font.family = "Cera Pro Regular, sans-serif";

      const size = new Chart(this.$refs["size"], {
        type: "bar",
        data: this.size,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Area (m²)",
              },
            },
          },
        },
      });

      this.$watch("size", () => {
        size.data = this.size;
        size.update();
      });

      const insulation = new Chart(this.$refs["insulation"], {
        type: "bar",
        data: this.insulation,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Heat Loss (W/K)",
              },
            },
          },
        },
      });

      this.$watch("insulation", () => {
        insulation.data = this.insulation;
        insulation.update();
      });

      const bridges = new Chart(this.$refs["bridges"], {
        type: "bar",
        data: this.bridges,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Heat Loss (W/K)",
              },
            },
          },
        },
      });

      this.$watch("bridges", () => {
        bridges.data = this.bridges;
        bridges.update();
      });

      const ventilation = new Chart(this.$refs["ventilation"], {
        type: "bar",
        data: this.ventilation,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Heat Loss (W/K)",
              },
            },
          },
        },
      });

      this.$watch("ventilation", () => {
        ventilation.data = this.ventilation;
        ventilation.update();
      });

      const gains = new Chart(this.$refs["gains"], {
        type: "line",
        data: this.gains,
        options: {
          plugins: {
            legend: {
              display: false,
              labels: {
                borderRadius: 1,
                useBorderRadius: true,
              },
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Heat Gain (W)",
              },
            },
          },
        },
      });

      this.$watch("gains", () => {
        gains.data = this.gains;
        gains.update();
      });

      const temperature = new Chart(this.$refs["temperature"], {
        type: "line",
        data: this.temperature,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              min: 0,
              title: {
                display: true,
                text: "Temperature (C)",
              },
            },
          },
        },
      });

      this.$watch("temperature", () => {
        temperature.data = this.temperature;
        temperature.update();
      });

      const space_heating = new Chart(this.$refs["space_heating"], {
        type: "bar",
        data: this.space_heating,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Energy (kWh)",
              },
            },
          },
        },
      });

      this.$watch("space_heating", () => {
        space_heating.data = this.space_heating;
        space_heating.update();
      });

      const total_energy = new Chart(this.$refs["total_energy"], {
        type: "bar",
        data: this.total_energy,
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                borderRadius: 1,
                useBorderRadius: true,
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              title: {
                display: true,
                text: "Energy (kWh)",
              },
            },
          },
        },
      });

      this.$watch("total_energy", () => {
        total_energy.data = this.total_energy;
        total_energy.update();
      });

      this.$watch("primary,comparison", () => {
        if (this.primary === null) {
          return;
        }

        this.floor = {
          area: this.primary.buildingElements
            .filter(({ variant }) => variant === "GroundFloor")
            .map(({ area }) => area)
            .reduce((s, x) => s + x, 0),
        };

        this.wall = {
          area: this.primary.buildingElements
            .filter(({ variant }) => variant === "ExternalWall")
            .map(({ area }) => area)
            .reduce((s, x) => s + x, 0),
        };

        this.opening = {
          area: this.primary.buildingElements
            .filter(({ variant }) => variant === "Window")
            .map(({ area }) => area)
            .reduce((s, x) => s + x, 0),
          count: this.primary.buildingElements.filter(
            ({ variant }) => variant === "Window",
          ).length,
        };

        if (size.options.plugins?.legend !== undefined) {
          size.options.plugins.legend.display = this.comparison !== null;
        }

        this.size = {
          labels: ["Windows", "Ground Floor", "External Walls", "Roof"],
          datasets: [
            {
              backgroundColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 0.5 : 0.1
              })`,
              borderColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.buildingElements
                  .filter(({ variant }) => variant === "Window")
                  .reduce((s, { area }) => s + area, 0),
                this.primary.buildingElements
                  .filter(({ variant }) => variant === "GroundFloor")
                  .reduce((s, { area }) => s + area, 0),
                this.primary.buildingElements
                  .filter(({ variant }) => variant === "ExternalWall")
                  .reduce((s, { area }) => s + area, 0),
                this.primary.buildingElements
                  .filter(({ variant }) => variant === "Roof")
                  .reduce((s, { area }) => s + area, 0),
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.size.datasets.push({
            backgroundColor: "rgb(57, 150, 233, 0.5)",
            borderColor: "rgb(57, 150, 233, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.buildingElements
                .filter(({ variant }) => variant === "Window")
                .reduce((s, { area }) => s + area, 0),
              this.comparison.buildingElements
                .filter(({ variant }) => variant === "GroundFloor")
                .reduce((s, { area }) => s + area, 0),
              this.comparison.buildingElements
                .filter(({ variant }) => variant === "ExternalWall")
                .reduce((s, { area }) => s + area, 0),
              this.comparison.buildingElements
                .filter(({ variant }) => variant === "Roof")
                .reduce((s, { area }) => s + area, 0),
            ],
            label: "Comparison",
          });
        }

        if (insulation.options.plugins?.legend !== undefined) {
          insulation.options.plugins.legend.display = this.comparison !== null;
        }

        this.insulation = {
          labels: ["Windows", "Ground Floor", "External Wall", "Roof"],
          datasets: [
            {
              backgroundColor: `rgb(87, 182, 111, ${
                this.comparison === null ? 0.5 : 0.1
              })`,
              borderColor: `rgb(87, 182, 111, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.windowFabricHeatLoss27 +
                  this.primary.roofWindowFabricHeatLoss27a,
                this.primary.basementFloorFabricHeatLoss28 +
                  this.primary.groundFloorFabricHeatLoss28a +
                  this.primary.exposedFloorFabricHeatLoss28b,
                this.primary.basementWallFabricHeatLoss29 +
                  this.primary.externalWallFabricHeatLoss29a +
                  this.primary.partyWallFabricHeatLoss32,
                this.primary.roofFabricHeatLoss30,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.insulation.datasets.push({
            backgroundColor: "rgb(87, 182, 111, 0.5)",
            borderColor: "rgb(87, 182, 111, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.windowFabricHeatLoss27 +
                this.comparison.roofWindowFabricHeatLoss27a,
              this.comparison.basementFloorFabricHeatLoss28 +
                this.comparison.groundFloorFabricHeatLoss28a +
                this.comparison.exposedFloorFabricHeatLoss28b,
              this.comparison.basementWallFabricHeatLoss29 +
                this.comparison.externalWallFabricHeatLoss29a +
                this.comparison.partyWallFabricHeatLoss32,
              this.comparison.roofFabricHeatLoss30,
            ],
            label: "Comparison",
          });
        }

        if (bridges.options.plugins?.legend !== undefined) {
          bridges.options.plugins.legend.display = this.comparison !== null;
        }

        this.bridges = {
          labels: [
            "Bridges",
            "Windows",
            "Ground Floor",
            "External Wall",
            "Roof",
          ],
          datasets: [
            {
              backgroundColor: [
                `rgb(248, 100, 63, ${this.comparison === null ? 0.5 : 0.1})`,
                "rgb(248, 100, 63, 0.1)",
                "rgb(248, 100, 63, 0.1)",
                "rgb(248, 100, 63, 0.1)",
                "rgb(248, 100, 63, 0.1)",
              ],
              borderColor: `rgb(248, 100, 63, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.linearThermalBridges36 +
                  this.primary.pointThermalBridges36a,
                this.primary.windowFabricHeatLoss27 +
                  this.primary.roofWindowFabricHeatLoss27a,
                this.primary.basementFloorFabricHeatLoss28 +
                  this.primary.groundFloorFabricHeatLoss28a +
                  this.primary.exposedFloorFabricHeatLoss28b,
                this.primary.basementWallFabricHeatLoss29 +
                  this.primary.externalWallFabricHeatLoss29a +
                  this.primary.partyWallFabricHeatLoss32,
                this.primary.roofFabricHeatLoss30,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.bridges.datasets.push({
            backgroundColor: [
              "rgb(248, 100, 63, 0.5)",
              "rgb(248, 100, 63, 0.1)",
              "rgb(248, 100, 63, 0.1)",
              "rgb(248, 100, 63, 0.1)",
              "rgb(248, 100, 63, 0.1)",
            ],
            borderColor: "rgb(248, 100, 63, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.linearThermalBridges36 +
                this.comparison.pointThermalBridges36a,
              this.comparison.windowFabricHeatLoss27 +
                this.comparison.roofWindowFabricHeatLoss27a,
              this.comparison.basementFloorFabricHeatLoss28 +
                this.comparison.groundFloorFabricHeatLoss28a +
                this.comparison.exposedFloorFabricHeatLoss28b,
              this.comparison.basementWallFabricHeatLoss29 +
                this.comparison.externalWallFabricHeatLoss29a +
                this.comparison.partyWallFabricHeatLoss32,
              this.comparison.roofFabricHeatLoss30,
            ],
            label: "Comparison",
          });
        }

        if (ventilation.options.plugins?.legend !== undefined) {
          ventilation.options.plugins.legend.display = this.comparison !== null;
        }

        this.ventilation = {
          labels: [
            "Ventilation",
            "Bridges",
            "Windows",
            "Ground Floor",
            "External Wall",
            "Roof",
          ],
          datasets: [
            {
              backgroundColor: [
                `rgb(163, 107, 224, ${this.comparison === null ? 0.5 : 0.1})`,
                "rgb(163, 107, 224, 0.1)",
                "rgb(163, 107, 224, 0.1)",
                "rgb(163, 107, 224, 0.1)",
                "rgb(163, 107, 224, 0.1)",
                "rgb(163, 107, 224, 0.1)",
              ],
              borderColor: `rgb(163, 107, 224, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                Object.values(this.primary.ventilationHeatLoss38).reduce(
                  (s, x) => s + x,
                  0,
                ) / 12,
                this.primary.linearThermalBridges36 +
                  this.primary.pointThermalBridges36a,
                this.primary.windowFabricHeatLoss27 +
                  this.primary.roofWindowFabricHeatLoss27a,
                this.primary.basementFloorFabricHeatLoss28 +
                  this.primary.groundFloorFabricHeatLoss28a +
                  this.primary.exposedFloorFabricHeatLoss28b,
                this.primary.basementWallFabricHeatLoss29 +
                  this.primary.externalWallFabricHeatLoss29a +
                  this.primary.partyWallFabricHeatLoss32,
                this.primary.roofFabricHeatLoss30,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.ventilation.datasets.push({
            backgroundColor: [
              "rgb(163, 107, 224, 0.5)",
              "rgb(163, 107, 224, 0.1)",
              "rgb(163, 107, 224, 0.1)",
              "rgb(163, 107, 224, 0.1)",
              "rgb(163, 107, 224, 0.1)",
              "rgb(163, 107, 224, 0.1)",
            ],
            borderColor: "rgb(163, 107, 224, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              Object.values(this.comparison.ventilationHeatLoss38).reduce(
                (s, x) => s + x,
                0,
              ) / 12,
              this.comparison.linearThermalBridges36 +
                this.comparison.pointThermalBridges36a,
              this.comparison.windowFabricHeatLoss27 +
                this.comparison.roofWindowFabricHeatLoss27a,
              this.comparison.basementFloorFabricHeatLoss28 +
                this.comparison.groundFloorFabricHeatLoss28a +
                this.comparison.exposedFloorFabricHeatLoss28b,
              this.comparison.basementWallFabricHeatLoss29 +
                this.comparison.externalWallFabricHeatLoss29a +
                this.comparison.partyWallFabricHeatLoss32,
              this.comparison.roofFabricHeatLoss30,
            ],
            label: "Comparison",
          });
        }

        if (gains.options.plugins?.legend !== undefined) {
          gains.options.plugins.legend.display = this.comparison !== null;
        }

        this.gains = {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              backgroundColor: `rgb(163, 107, 224, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderColor: `rgb(163, 107, 224, ${
                this.comparison === null ? 1 : 0.5
              })`,
              data: [
                this.primary.waterHeatGains65.jan +
                  this.primary.totalInternalGains73.jan +
                  this.primary.solarGains83.jan,
                this.primary.waterHeatGains65.feb +
                  this.primary.totalInternalGains73.feb +
                  this.primary.solarGains83.feb,
                this.primary.waterHeatGains65.mar +
                  this.primary.totalInternalGains73.mar +
                  this.primary.solarGains83.mar,
                this.primary.waterHeatGains65.apr +
                  this.primary.totalInternalGains73.apr +
                  this.primary.solarGains83.apr,
                this.primary.waterHeatGains65.may +
                  this.primary.totalInternalGains73.may +
                  this.primary.solarGains83.may,
                this.primary.waterHeatGains65.jun +
                  this.primary.totalInternalGains73.jun +
                  this.primary.solarGains83.jun,
                this.primary.waterHeatGains65.jul +
                  this.primary.totalInternalGains73.jul +
                  this.primary.solarGains83.jul,
                this.primary.waterHeatGains65.aug +
                  this.primary.totalInternalGains73.aug +
                  this.primary.solarGains83.aug,
                this.primary.waterHeatGains65.sep +
                  this.primary.totalInternalGains73.sep +
                  this.primary.solarGains83.sep,
                this.primary.waterHeatGains65.oct +
                  this.primary.totalInternalGains73.oct +
                  this.primary.solarGains83.oct,
                this.primary.waterHeatGains65.nov +
                  this.primary.totalInternalGains73.nov +
                  this.primary.solarGains83.nov,
                this.primary.waterHeatGains65.dec +
                  this.primary.totalInternalGains73.dec +
                  this.primary.solarGains83.dec,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.gains.datasets.push({
            backgroundColor: "rgb(163, 107, 224, 1)",
            borderColor: "rgb(163, 107, 224, 1)",
            label: "Comparison",
            data: [
              this.comparison.waterHeatGains65.jan +
                this.comparison.totalInternalGains73.jan +
                this.comparison.solarGains83.jan,
              this.comparison.waterHeatGains65.feb +
                this.comparison.totalInternalGains73.feb +
                this.comparison.solarGains83.feb,
              this.comparison.waterHeatGains65.mar +
                this.comparison.totalInternalGains73.mar +
                this.comparison.solarGains83.mar,
              this.comparison.waterHeatGains65.apr +
                this.comparison.totalInternalGains73.apr +
                this.comparison.solarGains83.apr,
              this.comparison.waterHeatGains65.may +
                this.comparison.totalInternalGains73.may +
                this.comparison.solarGains83.may,
              this.comparison.waterHeatGains65.jun +
                this.comparison.totalInternalGains73.jun +
                this.comparison.solarGains83.jun,
              this.comparison.waterHeatGains65.jul +
                this.comparison.totalInternalGains73.jul +
                this.comparison.solarGains83.jul,
              this.comparison.waterHeatGains65.aug +
                this.comparison.totalInternalGains73.aug +
                this.comparison.solarGains83.aug,
              this.comparison.waterHeatGains65.sep +
                this.comparison.totalInternalGains73.sep +
                this.comparison.solarGains83.sep,
              this.comparison.waterHeatGains65.oct +
                this.comparison.totalInternalGains73.oct +
                this.comparison.solarGains83.oct,
              this.comparison.waterHeatGains65.nov +
                this.comparison.totalInternalGains73.nov +
                this.comparison.solarGains83.nov,
              this.comparison.waterHeatGains65.dec +
                this.comparison.totalInternalGains73.dec +
                this.comparison.solarGains83.dec,
            ],
          });
        }

        if (temperature.options.plugins?.legend !== undefined) {
          temperature.options.plugins.legend.display = this.comparison !== null;
        }

        this.temperature = {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              backgroundColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 1 : 0.5
              })`,
              data: [
                this.primary.adjustedMeanInternalTemperature93.jan,
                this.primary.adjustedMeanInternalTemperature93.feb,
                this.primary.adjustedMeanInternalTemperature93.mar,
                this.primary.adjustedMeanInternalTemperature93.apr,
                this.primary.adjustedMeanInternalTemperature93.may,
                this.primary.adjustedMeanInternalTemperature93.jun,
                this.primary.adjustedMeanInternalTemperature93.jul,
                this.primary.adjustedMeanInternalTemperature93.aug,
                this.primary.adjustedMeanInternalTemperature93.sep,
                this.primary.adjustedMeanInternalTemperature93.oct,
                this.primary.adjustedMeanInternalTemperature93.nov,
                this.primary.adjustedMeanInternalTemperature93.dec,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.temperature.datasets.push({
            backgroundColor: "rgb(57, 150, 233, 1)",
            borderColor: "rgb(57, 150, 233, 1)",
            data: [
              this.comparison.adjustedMeanInternalTemperature93.jan,
              this.comparison.adjustedMeanInternalTemperature93.feb,
              this.comparison.adjustedMeanInternalTemperature93.mar,
              this.comparison.adjustedMeanInternalTemperature93.apr,
              this.comparison.adjustedMeanInternalTemperature93.may,
              this.comparison.adjustedMeanInternalTemperature93.jun,
              this.comparison.adjustedMeanInternalTemperature93.jul,
              this.comparison.adjustedMeanInternalTemperature93.aug,
              this.comparison.adjustedMeanInternalTemperature93.sep,
              this.comparison.adjustedMeanInternalTemperature93.oct,
              this.comparison.adjustedMeanInternalTemperature93.nov,
              this.comparison.adjustedMeanInternalTemperature93.dec,
            ],
            label: "Comparison",
          });
        }

        if (space_heating.options.plugins?.legend !== undefined) {
          space_heating.options.plugins.legend.display =
            this.comparison !== null;
        }

        this.space_heating = {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              backgroundColor: `rgb(163, 107, 224, ${
                this.comparison === null ? 0.5 : 0.1
              })`,
              borderColor: `rgb(162, 107, 224, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.afterSolarSpaceHeating98cm.jan,
                this.primary.afterSolarSpaceHeating98cm.feb,
                this.primary.afterSolarSpaceHeating98cm.mar,
                this.primary.afterSolarSpaceHeating98cm.apr,
                this.primary.afterSolarSpaceHeating98cm.may,
                this.primary.afterSolarSpaceHeating98cm.jun,
                this.primary.afterSolarSpaceHeating98cm.jul,
                this.primary.afterSolarSpaceHeating98cm.aug,
                this.primary.afterSolarSpaceHeating98cm.sep,
                this.primary.afterSolarSpaceHeating98cm.oct,
                this.primary.afterSolarSpaceHeating98cm.nov,
                this.primary.afterSolarSpaceHeating98cm.dec,
              ],
              label: "Example",
            },
          ],
        };

        if (this.comparison !== null) {
          this.space_heating.datasets.push({
            backgroundColor: "rgb(163, 107, 224, 0.5)",
            borderColor: "rgb(162, 107, 224, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.afterSolarSpaceHeating98cm.jan,
              this.comparison.afterSolarSpaceHeating98cm.feb,
              this.comparison.afterSolarSpaceHeating98cm.mar,
              this.comparison.afterSolarSpaceHeating98cm.apr,
              this.comparison.afterSolarSpaceHeating98cm.may,
              this.comparison.afterSolarSpaceHeating98cm.jun,
              this.comparison.afterSolarSpaceHeating98cm.jul,
              this.comparison.afterSolarSpaceHeating98cm.aug,
              this.comparison.afterSolarSpaceHeating98cm.sep,
              this.comparison.afterSolarSpaceHeating98cm.oct,
              this.comparison.afterSolarSpaceHeating98cm.nov,
              this.comparison.afterSolarSpaceHeating98cm.dec,
            ],
            label: "Comparison",
          });
        }

        this.total_energy = {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              backgroundColor: `rgb(248, 100, 63, ${
                this.comparison === null ? 0.5 : 0.1
              })`,
              borderColor: `rgb(248, 100, 63, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.waterHeatingEnergyUsage219m.jan,
                this.primary.waterHeatingEnergyUsage219m.feb,
                this.primary.waterHeatingEnergyUsage219m.mar,
                this.primary.waterHeatingEnergyUsage219m.apr,
                this.primary.waterHeatingEnergyUsage219m.may,
                this.primary.waterHeatingEnergyUsage219m.jun,
                this.primary.waterHeatingEnergyUsage219m.jul,
                this.primary.waterHeatingEnergyUsage219m.aug,
                this.primary.waterHeatingEnergyUsage219m.sep,
                this.primary.waterHeatingEnergyUsage219m.oct,
                this.primary.waterHeatingEnergyUsage219m.nov,
                this.primary.waterHeatingEnergyUsage219m.dec,
              ],
              label:
                this.comparison === null ? "Hot Water" : "Hot Water Example",
              stack: "0",
            },
            {
              backgroundColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 0.5 : 0.1
              })`,
              borderColor: `rgb(57, 150, 233, ${
                this.comparison === null ? 1 : 0.5
              })`,
              borderRadius: 8,
              borderWidth: 2,
              data: [
                this.primary.primary1SpaceHeatingEnergyUsage211m.jan,
                this.primary.primary1SpaceHeatingEnergyUsage211m.feb,
                this.primary.primary1SpaceHeatingEnergyUsage211m.mar,
                this.primary.primary1SpaceHeatingEnergyUsage211m.apr,
                this.primary.primary1SpaceHeatingEnergyUsage211m.may,
                this.primary.primary1SpaceHeatingEnergyUsage211m.jun,
                this.primary.primary1SpaceHeatingEnergyUsage211m.jul,
                this.primary.primary1SpaceHeatingEnergyUsage211m.aug,
                this.primary.primary1SpaceHeatingEnergyUsage211m.sep,
                this.primary.primary1SpaceHeatingEnergyUsage211m.oct,
                this.primary.primary1SpaceHeatingEnergyUsage211m.nov,
                this.primary.primary1SpaceHeatingEnergyUsage211m.dec,
              ],
              label: this.comparison === null ? "Heating" : "Heating Example",
              stack: "0",
            },
          ],
        };

        if (this.comparison !== null) {
          this.total_energy.datasets.push({
            backgroundColor: "rgb(248, 100, 63, 0.5)",
            borderColor: "rgb(248, 100, 63, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.waterHeatingEnergyUsage219m.jan,
              this.comparison.waterHeatingEnergyUsage219m.feb,
              this.comparison.waterHeatingEnergyUsage219m.mar,
              this.comparison.waterHeatingEnergyUsage219m.apr,
              this.comparison.waterHeatingEnergyUsage219m.may,
              this.comparison.waterHeatingEnergyUsage219m.jun,
              this.comparison.waterHeatingEnergyUsage219m.jul,
              this.comparison.waterHeatingEnergyUsage219m.aug,
              this.comparison.waterHeatingEnergyUsage219m.sep,
              this.comparison.waterHeatingEnergyUsage219m.oct,
              this.comparison.waterHeatingEnergyUsage219m.nov,
              this.comparison.waterHeatingEnergyUsage219m.dec,
            ],
            label: "Hot Water Comparison",
            stack: "1",
          });

          this.total_energy.datasets.push({
            backgroundColor: "rgb(57, 150, 233, 0.5)",
            borderColor: "rgb(57, 150, 233, 1)",
            borderRadius: 8,
            borderWidth: 2,
            data: [
              this.comparison.primary1SpaceHeatingEnergyUsage211m.jan,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.feb,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.mar,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.apr,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.may,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.jun,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.jul,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.aug,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.sep,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.oct,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.nov,
              this.comparison.primary1SpaceHeatingEnergyUsage211m.dec,
            ],
            label: "Heating Comparison",
            stack: "1",
          });
        }

        this.sapRating258 = this.primary.sapRating258.toFixed();

        const total = Object.values(
          this.primary.primary1SpaceHeatingEnergyUsage211m,
        )
          .concat(Object.values(this.primary.waterHeatingEnergyUsage219m))
          .reduce((s, x) => s + x, 0);

        this.emissions = ((total * 0.316) / 1000).toFixed();

        this.cost = new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          maximumSignificantDigits: 2,
        }).format((total * 7) / 100);

        if (this.comparison !== null) {
          this.sapRating258Improvement = (
            this.comparison.sapRating258 - this.primary.sapRating258
          ).toFixed();

          const reduced = Object.values(
            this.comparison.primary1SpaceHeatingEnergyUsage211m,
          )
            .concat(Object.values(this.comparison.waterHeatingEnergyUsage219m))
            .reduce((s, x) => s + x, 0);

          this.emissionsReduction = (
            ((total - reduced) * 0.316) /
            1000
          ).toFixed();

          this.costReduction = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            maximumSignificantDigits: 2,
          }).format(((total - reduced) * 7) / 100);
        }
      });

      const calculate = (data: string): Promise<Worksheet | null> => {
        return new Promise((resolve) => {
          const result = Input.safeParse(JSON.parse(atob(data)));

          if (!result.success) {
            return resolve(null);
          }

          const sap = Worksheet.safeParse(result.data.sap);

          if (!sap.success) {
            return resolve(null);
          }

          return resolve(sap.data);
        });
      };

      this.primary = await calculate(data);
      this.comparison = await calculate(comparison);
    },
  };
};
