import * as React from "react";
import Modal from "@mui/material/Modal";

interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
}

export default function BasicModal({
  open,
  children,
  handleClose,
}: ModalProps) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 bg-white border-2 border-black shadow-md p-4">
          {children}
        </div>
      </Modal>
    </div>
  );
}
