import { AlpineComponent } from "alpinejs";
import { call } from "../fetch";
import { z } from "zod";

enum Status {
  Ready,
  Loading,
  Complete,
}

const MeasureItems = z.object({
  pk: z.number().optional(),
  category: z.string(),
  plan_notes: z.string().optional(),
  plan_cost: z.coerce
    .number()
    .multipleOf(0.01)
    .transform((val) => val * 100),
  annual_cost_savings: z.coerce
    .number()
    .multipleOf(0.01)
    .transform((val) => val * 100),
  annual_carbon_savings: z.coerce.number().multipleOf(0.01).optional(),
  energy_rating_improvement: z.coerce.number().multipleOf(0.01).optional(),
  name: z.string().optional(),
  favourite: z.boolean().default(false),
  url: z.string(),
  image_url: z.string().nullable(),
});

const BasketMeasureList = z.array(MeasureItems);

export type BasketMeasureType = z.infer<typeof MeasureItems>;

interface State extends Record<string | symbol, unknown> {
  status: Status;
  measureItems: BasketMeasureType[];
  favouriteMeasures: () => BasketMeasureType[];
  add: (itemId: number, event: Event) => void;
  remove: (itemId: number, event: Event) => void;
  totalCost: () => string;
  totalSavings: () => string;
  isFavourite: (itemId: number) => boolean;
  submit: (event: Event) => void;
  formatEnergyScore: (value: number) => string;
  basketOpen: boolean;
  proposalRequested: boolean;
}

export default (data: unknown): AlpineComponent<State> => ({
  measureItems:
    typeof data === "object" && BasketMeasureList.safeParse(data).success
      ? BasketMeasureList.parse(data)
      : [],
  status: Status.Ready,
  basketOpen: true,
  proposalRequested: false,

  add(itemId: number, event: Event) {
    if (this.status !== Status.Ready) {
      return;
    }
    event.preventDefault();
    const itemIndex = this.measureItems.findIndex((i) => i.pk === itemId);
    if (itemIndex === -1) {
      return;
    }
    const itemToAdd = this.measureItems[itemIndex];
    if (itemToAdd) {
      itemToAdd.favourite = true;
      this.submit(event);
    }
  },

  isFavourite(itemId: number) {
    return this.measureItems.some((i) => i.pk === itemId && i.favourite);
  },

  favouriteMeasures() {
    return this.measureItems.filter((i) => i.favourite);
  },

  remove(itemId: number, event: Event) {
    if (this.status !== Status.Ready) {
      return;
    }
    event.preventDefault();
    const itemIndex = this.measureItems.findIndex((i) => i.pk === itemId);
    if (itemIndex === -1) {
      return;
    }
    const itemToAdd = this.measureItems[itemIndex];
    if (itemToAdd) {
      itemToAdd.favourite = false;
      this.submit(event);
    }
  },

  formatEnergyScore(value) {
    value = Math.floor(value);
    return value > 0 ? "+" + value : value.toString();
  },

  totalCost() {
    const cost = this.favouriteMeasures().reduce((acc, item) => {
      return acc + item.plan_cost;
    }, 0);
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    }).format(cost / 100);
  },

  totalSavings() {
    const savings = this.favouriteMeasures().reduce((acc, item) => {
      return acc + item.annual_cost_savings;
    }, 0);
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    }).format(savings / 100);
  },

  async submit(event: Event) {
    event.preventDefault();
    if (event.target === null) {
      return;
    }
    if (this.status === Status.Ready) {
      this.status = Status.Loading;

      if (!(event.target instanceof Element)) {
        return;
      }

      const form = event.target.closest("form");
      if (!form) {
        return;
      }
      const body = new FormData(form);

      const crsf = body.get("csrfmiddlewaretoken");
      if (typeof crsf !== "string") {
        return;
      }
      await call(form.action, {
        body,
        headers: {
          Accept: "application/json",
          "X-CSRFToken": crsf,
        },
        method: "PATCH",
      });

      this.status = Status.Ready;
      this.basketOpen = true;
    }
  },

  async requestProposal(event: Event) {
    event.preventDefault();
    if (event.target === null) {
      return;
    }
    if (this.status === Status.Ready) {
      this.status = Status.Loading;

      if (!(event.target instanceof Element)) {
        return;
      }

      const form = event.target.closest("form");
      if (!form) {
        return;
      }
      const body = new FormData(form);

      const crsf = body.get("csrfmiddlewaretoken");
      if (typeof crsf !== "string") {
        return;
      }
      await call(form.action, {
        headers: {
          "X-CSRFToken": crsf,
        },
        method: "GET",
      });
      this.proposalRequested = true;
      this.status = Status.Ready;
    }
  },

  disabled() {
    return this.status !== Status.Ready;
  },
  ready() {
    return this.status === Status.Ready;
  },
});
