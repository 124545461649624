import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  elements: Map<string, boolean>;
  onResize(): void;
}

export default (): AlpineComponent<State> => ({
  active(section: unknown, ...classes: unknown[]): void {
    if (typeof section !== "string") {
      return;
    }

    if (!classes.every((x): x is string => typeof x === "string")) {
      return;
    }

    const entries = Array.from(this.elements.entries());

    const [first] = entries.find(([, visible]) => visible) ?? [
      undefined,
      undefined,
    ];

    if (first === undefined) {
      return;
    }

    if (first === section) {
      this.$el.classList.add(...classes);
      this.$el.offsetParent?.scrollTo({
        left: this.$el.offsetLeft,
        behavior: "smooth",
      });
    } else {
      this.$el.classList.remove(...classes);
    }
  },

  nav() {
    window.addEventListener("resize", this.onResize.bind(this));

    this.onResize();
  },

  onResize() {
    const children = Array.from(this.$el.children);

    const last = children.at(-2);
    const padding = children.at(-1);

    if (!(last instanceof HTMLElement && padding instanceof HTMLElement)) {
      return;
    }

    padding.style.width = `${this.$el.clientWidth - last.clientWidth - 16}px`;
  },

  section(id: unknown) {
    if (typeof id !== "string") {
      return;
    }

    const element = this.$el;

    this.elements.set(id, false);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.elements.set(id, entry.isIntersecting);
        });
      },
      {
        rootMargin: "-10%",
      },
    );

    observer.observe(element);
  },

  elements: new Map(),
});
