import { AlpineComponent } from "alpinejs";
import { z } from "zod";

import { equalDate, equalMonth, equalTime } from "../dates";

interface State extends Record<string | symbol, unknown> {
  availability: Duration[];
  current: Date;
  now: Date;
  selected: Date;
  start: Date | null;
  end: Date | null;
  output: Duration | null;
}

interface Methods {
  month(): Date[];
  slots(): Duration[];
  week(): Date[];
}

const Duration = z.object({
  start: z.coerce.date(),
  length: z.number(),
});

type Duration = z.infer<typeof Duration>;

export default (value: unknown): AlpineComponent<State & Methods> => {
  const result = z
    .array(Duration)
    .safeParse(JSON.parse(typeof value === "string" ? value : ""));

  const availability = result.success ? result.data : [];

  const now = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const selected = now;
  const current = new Date(new Date(now).setUTCDate(1));

  return {
    availability,
    current,
    now,
    selected,
    start: null,
    end: null,
    output: null,
    next(event: Event) {
      event.preventDefault();

      this.current = new Date(
        new Date(this.current).setUTCMonth(this.current.getUTCMonth() + 1),
      );
    },
    prev(event: Event) {
      event.preventDefault();

      this.current = new Date(
        new Date(this.current).setUTCMonth(this.current.getUTCMonth() - 1),
      );
    },
    month() {
      const start = new Date(
        new Date(this.current).setUTCDate(startOfWeekOffset(this.current)),
      );

      return [...Array(35).keys()].map(
        (x) => new Date(new Date(start).setUTCDate(start.getUTCDate() + x)),
      );
    },
    slots() {
      const date = new Date(
        this.selected.getUTCFullYear(),
        this.selected.getUTCMonth(),
        this.selected.getUTCDate(),
      );

      return [...Array(16).keys()].map((x) => ({
        start: new Date(new Date(date).setSeconds(9 * 60 * 60 + x * 30 * 60)),
        length: 30 * 60,
      }));
    },
    week() {
      return [...Array(7).keys()].map(
        (x) =>
          new Date(
            new Date(this.selected).setUTCDate(
              this.selected.getUTCDate() + x - 3,
            ),
          ),
      );
    },
    pick(value: unknown) {
      const result = Duration.safeParse(value);

      if (!result.success) {
        return;
      }

      const slot = result.data;

      if (!slotAvailable(slot, this.availability)) {
        return;
      }

      if (
        this.start !== null &&
        this.end === null &&
        equalTime(this.start, slot.start)
      ) {
        return;
      }

      if (this.start === null || this.end !== null) {
        this.start = slot.start;
        this.end = null;
        this.output = null;
      } else {
        if (slot.start < this.start) {
          this.end = this.start;
          this.start = slot.start;
        } else {
          this.end = slot.start;
        }
        this.output = {
          start: this.start,
          length:
            (this.end.valueOf() - this.start.valueOf()) / 1000 + slot.length,
        };
      }
    },
    select(value: unknown): void {
      if (!(value instanceof Date)) {
        return;
      }

      this.selected = value;
    },
    monthdayButtonClasses(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      const classes = [];

      if (equalMonth(this.current, value)) {
        if (this.availability.some((x) => equalDate(x.start, value))) {
          classes.push("bg-green-100");
        } else {
          classes.push("bg-white");
        }
      } else {
        classes.push("bg-gray-50");
      }

      if (equalDate(this.selected, value) || equalDate(this.now, value)) {
        classes.push("font-semibold");
      }

      if (equalDate(this.selected, value)) {
        classes.push("text-white");
      }

      if (
        !equalDate(this.selected, value) &&
        !equalDate(this.now, value) &&
        equalMonth(this.current, value)
      ) {
        classes.push("text-gray-900");
      }

      if (
        !equalDate(this.selected, value) &&
        !equalDate(this.now, value) &&
        !equalMonth(this.current, value)
      ) {
        classes.push("text-gray-400");
      }

      if (!equalDate(this.selected, value) && equalDate(this.now, value)) {
        classes.push("text-indigo-600");
      }

      if (this.month().indexOf(value) === 0) {
        classes.push("rounded-tl-lg");
      }

      if (this.month().indexOf(value) === 6) {
        classes.push("rounded-tr-lg");
      }

      if (this.month().indexOf(value) === 28) {
        classes.push("rounded-bl-lg");
      }

      if (this.month().indexOf(value) === 34) {
        classes.push("rounded-br-lg");
      }

      return classes.join(" ");
    },
    monthdayClasses(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      if (equalDate(this.selected, value)) {
        if (equalDate(this.now, value)) {
          return "bg-indigo-600";
        } else {
          return "bg-gray-900";
        }
      }

      return "";
    },
    monthdayTitle(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
      }).format(value);
    },
    monthviewTitle(): string {
      return new Intl.DateTimeFormat("en-GB", {
        month: "long",
        year: "numeric",
      }).format(this.current);
    },
    containerTitle(): string {
      return new Intl.DateTimeFormat("en-GB", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(this.selected);
    },
    containerWeekday(): string {
      return new Intl.DateTimeFormat("en-GB", {
        weekday: "long",
      }).format(this.selected);
    },
    dayviewClasses(value: unknown) {
      const result = Duration.safeParse(value);

      if (!result.success) {
        return;
      }

      const slot = result.data;

      if (slotAvailable(slot, this.availability)) {
        if (this.start !== null && equalTime(this.start, slot.start)) {
          return "bg-green-100 cursor-pointer";
        }

        if (this.end !== null && equalTime(this.end, slot.start)) {
          return "bg-green-100 cursor-pointer";
        }

        if (this.start !== null && this.end !== null) {
          if (slot.start.valueOf() < this.end.valueOf()) {
            if (slot.start.valueOf() > this.start.valueOf()) {
              return "bg-green-100 cursor-pointer";
            }
          }
        }

        return "bg-green-50 cursor-pointer";
      }
      return "bg-red-50 cursor-auto";
    },
    dayviewText(value: unknown) {
      const result = Duration.safeParse(value);

      if (!result.success) {
        return;
      }

      const slot = result.data;

      if (slotAvailable(slot, this.availability)) {
        return "Available";
      }
      return "Unavailable";
    },
    dayviewRows(value: unknown) {
      if (typeof value !== "number") {
        return "";
      }

      return `grid-template-rows: repeat(${value}, minmax(3.5rem, 1fr))`;
    },
    dayviewTime(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
      }).format(value);
    },
    weekviewClasses(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      const available = this.availability.some((x) =>
        equalDate(x.start, value),
      );

      const classes = [];

      if (
        !available &&
        equalDate(this.now, value) &&
        !equalDate(this.selected, value)
      ) {
        classes.push("text-indigo-600");
      }

      if (
        !available &&
        equalDate(this.now, value) &&
        equalDate(this.selected, value)
      ) {
        classes.push("bg-indigo-600", "text-white");
      }

      if (
        !available &&
        !equalDate(this.now, value) &&
        equalDate(this.selected, value)
      ) {
        classes.push("bg-gray-900", "text-white");
      }

      if (!equalDate(this.now, value) && !equalDate(this.selected, value)) {
        classes.push("text-gray-900");
      }

      if (available && equalDate(this.selected, value)) {
        classes.push("bg-green-400 text-gray-900");
      }

      if (available && !equalDate(this.selected, value)) {
        classes.push("bg-green-100");
      }

      return classes.join(" ");
    },
    weekviewTitle(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", { day: "numeric" }).format(value);
    },
    weekviewWeekday(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      return new Intl.DateTimeFormat("en-GB", { weekday: "narrow" }).format(
        value,
      );
    },
  };
};

const startOfWeekOffset = (value: Date): number => {
  const day = value.getUTCDay();
  const date = value.getUTCDate();

  if (day === 0) {
    return date - 6;
  } else if (day === 1) {
    return date;
  } else if (day === 2) {
    return date - 1;
  } else if (day === 3) {
    return date - 2;
  } else if (day === 4) {
    return date - 3;
  } else if (day === 5) {
    return date - 4;
  } else if (day === 6) {
    return date - 5;
  }

  return date;
};

const slotAvailable = (slot: Duration, availability: Duration[]): boolean => {
  return availability.some((x) => {
    if (slot.start.valueOf() < x.start.valueOf()) {
      return false;
    }

    if (
      slot.start.valueOf() + 1000 * slot.length >
      x.start.valueOf() + 1000 * x.length
    ) {
      return false;
    }

    return true;
  });
};
