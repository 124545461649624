export type Result<T, E> = { ok: true; value: T } | { ok: false; error: E };

export const call = async (
  url: string,
  options: RequestInit = {},
): Promise<Result<unknown, unknown>> => {
  try {
    const response = await fetch(url, options);

    if (response.status === 204) {
      return { ok: true, value: undefined };
    }

    const value: unknown = await response.json();

    return { ok: true, value };
  } catch (error) {
    return { ok: false, error };
  }
};
