import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  exportToPDF(): void;
}

export default (): AlpineComponent<State> => ({
  exportToPDF() {
    window.print();
  },
});
