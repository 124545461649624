import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  files: File[];
  error: string | null;
}

export default (): AlpineComponent<State> => ({
  files: [],
  error: null,
  change(event: unknown) {
    if (typeof event !== "object" || event === null) {
      return;
    }

    if (!("target" in event)) {
      return;
    }

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    this.files = Array.from(event.target.files ?? []);
    this.error = null;
  },
  empty() {
    return this.files.length === 0;
  },
  handleDrop(event: DragEvent) {
    const fileInput = this.$refs["input"];
    if (!fileInput || !(fileInput instanceof HTMLInputElement)) {
      return;
    }

    this.files = [];
    event.preventDefault();
    this.error = null;
    const fileList = event.dataTransfer?.files ?? [];
    const dT = new DataTransfer();

    for (const file of fileList) {
      const fileType = file.type.toLowerCase();
      if (
        !fileType ||
        (fileType !== "application/pdf" && !fileType.startsWith("image/"))
      ) {
        this.error = "Only PDF and image files are allowed";
      } else {
        dT.items.add(file);
        this.files.push(file);
      }
    }
    fileInput.files = dT.files;
  },
  removeStoredFile(pk: number) {
    const hiddenFileInput = document.getElementById(
      `file_${pk}`,
    ) as HTMLInputElement;
    hiddenFileInput.remove();
  },
  removeFile(name: string) {
    const fileInput = this.$refs["input"];
    if (
      !fileInput ||
      !(fileInput instanceof HTMLInputElement) ||
      !fileInput.files
    ) {
      return;
    }
    const dt = new DataTransfer();

    for (const file of fileInput.files) {
      if (file.name !== name) {
        dt.items.add(file);
      }
    }

    fileInput.files = dt.files;
    this.files = this.files.filter((file) => file.name !== name);
  },
});
