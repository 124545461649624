import { AlpineComponent } from "alpinejs";

import { Address, Suggestion, autocomplete, getAddress } from "./getaddress";

interface State extends Record<string | symbol, unknown> {
  address: Address;
  key: string;
  show: boolean;
  suggestions: Suggestion[];
}

const initial = (key: unknown): State => ({
  address: {
    street: "",
    city: "",
    postcode: "",
  },
  key: typeof key == "string" ? key : "",
  show: false,
  suggestions: [],
});

export default (key: unknown): AlpineComponent<State> => ({
  ...initial(key),
  async input() {
    if (this.address.street.length < 4) {
      return;
    }

    const suggestions = await autocomplete(this.address.street, this.key);

    if (suggestions.ok) {
      this.suggestions = suggestions.value;
    }

    this.show = this.suggestions.length > 0;
  },
  async click(event: Event) {
    event.preventDefault();

    if (
      event.target !== null &&
      "id" in event.target &&
      typeof event.target.id == "string"
    ) {
      const address = await getAddress(event.target.id, this.key);

      if (address.ok) {
        this.address = address.value;

        this.show = false;
      }
    }
  },
  hide() {
    this.show = false;
  },
});
