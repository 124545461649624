import {
  Control,
  FieldValues,
  Path,
  useController,
  useFormContext,
  useFieldArray,
} from "react-hook-form";

import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  EditPlanFormSchemaType,
  ProjectFileSchema,
  ProjectFileSchemaType,
  UploadedFileSchema,
} from "../schemas";
import { FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import { call } from "../../fetch";
import { TableCell, TableHeader, TableRow } from "./tables";

interface Props<TFieldValues extends FieldValues> {
  allowDelete?: boolean;
  control: Control<TFieldValues, unknown>;
  name: Path<TFieldValues>;
}

const encodeFile = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};

const Preview = ({ value }: { value: unknown }) => {
  const result = UploadedFileSchema.safeParse(value);

  if (!result.success || result.data === null) {
    return null;
  }

  if ("url" in result.data) {
    return (
      <img
        className="max-w-60 max-h-60 object-scale-down"
        src={result.data.url}
      />
    );
  }

  return (
    <img
      className="max-w-60 max-h-60 object-scale-down"
      src={result.data.file.content}
    />
  );
};

export const FileUploadComponent = <TFieldValues extends FieldValues>({
  allowDelete = true,
  control,
  name,
}: Props<TFieldValues>) => {
  const { field } = useController({
    name,
    control,
  });

  const onChange = async (event: Event): Promise<void> => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const file = event.target.files?.[0];

    if (file === undefined) {
      return;
    }

    return field.onChange({
      file: {
        content: await encodeFile(file),
        "content-type": file.type,
        filename: file.name,
      },
      labels: [],
    });
  };

  const onDelete = () => field.onChange(null);

  return (
    <div class="flex flex-col items-center justify-center gap-y-4 py-4 bg-gray-200 rounded-lg">
      <Preview value={field.value} />
      <div className="flex gap-x-4">
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload File
          <input
            type="file"
            className="hidden"
            onChange={onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
          />
        </Button>
        {field.value && allowDelete && (
          <Button color="warning" onClick={onDelete} variant="contained">
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

interface FileTableComponentProps {
  fileList: ProjectFileSchemaType[];
  fileUrl: string;
  csrf: string;
  append: (value: ProjectFileSchemaType) => void;
}

export const FileTableComponent: FunctionalComponent<
  FileTableComponentProps
> = ({ fileList, fileUrl, append, csrf }) => {
  const [uploading, setUploading] = useState(false);
  const [errorFiles, setErrorFiles] = useState<string[]>([]);

  const upload = async (e: Event): Promise<void> => {
    setErrorFiles([]);
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }

    if (e.target.files === null) {
      return;
    }

    setUploading(true);
    for (const file of e.target.files) {
      const body = new FormData();
      body.append("csrfmiddlewaretoken", csrf);
      body.append("file", file);
      const response = await call(fileUrl, {
        body,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
      });
      if (response.ok) {
        const parse = ProjectFileSchema.safeParse(response.value);
        if (parse.success) {
          append(parse.data);
        }
      } else {
        setErrorFiles((prev) => [...prev, file.name]);
      }
    }
    setUploading(false);
  };

  return (
    <div>
      <div class="flex flex-col items-center justify-center gap-y-4 py-4 bg-gray-100 rounded-lg mb-2">
        <div className="flex flex-col w-full items-center justify-center">
          <Button
            component="label"
            variant="contained"
            className="w-40"
            startIcon={<CloudUploadIcon />}
          >
            {!uploading ? "Upload File" : "Uploading..."}
            <input
              type="file"
              className="hidden"
              onChange={upload}
              multiple
              disabled={uploading}
            />
          </Button>
          {!!errorFiles.length && (
            <div class="bg-red-100 p-2 text-red-500 text-xs mt-2 text-center">
              <p>The following files could not be uploaded:</p>
              <ul>
                {errorFiles.map((filename) => (
                  <li key={filename}>{filename}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <table className="w-full">
        <thead>
          <TableRow>
            <TableHeader>Filename</TableHeader>
            <TableHeader>Uploaded</TableHeader>
            <TableHeader>Owner</TableHeader>
            <TableHeader>Label</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {fileList &&
            fileList.map((file: ProjectFileSchemaType) => (
              <FileRow key={file.pk} value={file} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

const FileRow = ({ value }: { value: unknown }) => {
  const result = ProjectFileSchema.safeParse(value);

  if (!result.success || result.data === null) {
    return null;
  }

  return (
    <TableRow>
      <TableCell>{result.data.filename}</TableCell>
      <TableCell>
        {new Date(result.data.created).toLocaleDateString()}
      </TableCell>
      <TableCell>{result.data.user}</TableCell>
      <TableCell>{result.data.labels}</TableCell>
    </TableRow>
  );
};

export const FormFileTableComponent = ({ csrf }: { csrf: string }) => {
  const { control, getValues } = useFormContext<EditPlanFormSchemaType>();
  const { fields, append } = useFieldArray({
    control,
    name: "project_files",
  });

  return (
    <FileTableComponent
      fileList={fields}
      fileUrl={getValues("file_url")}
      append={append}
      csrf={csrf}
    />
  );
};
