import { AlpineComponent } from "alpinejs";

import { call } from "../fetch";

enum Status {
  Ready,
  Loading,
  Complete,
}

interface State extends Record<string | symbol, unknown> {
  status: Status;
}

export default (): AlpineComponent<State> => ({
  status: Status.Ready,
  async submit(event: Event) {
    event.preventDefault();

    if (this.status === Status.Ready) {
      this.status = Status.Loading;
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const form = event.target.closest("form");
      if (!form) {
        return;
      }
      if (form instanceof HTMLFormElement) {
        const body = new FormData(form);
        await call(form.action, {
          body,
          method: "POST",
          redirect: "manual",
        });

        this.status = Status.Complete;
      }
    }
  },
  disabled() {
    return this.status !== Status.Ready;
  },
  ready() {
    return this.status === Status.Ready;
  },
  loading() {
    return this.status === Status.Loading;
  },
  complete() {
    return this.status === Status.Complete;
  },
});
