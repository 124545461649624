import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  showTooltip: boolean;
  setVisible: (visible: boolean) => void;
}

export default (): AlpineComponent<State> => ({
  showTooltip: false,

  setVisible(visible: boolean) {
    this.showTooltip = visible;
  },
});
