import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  slideCount: number | null;
  slide: number;
  setSlide: (slide: number) => void;
  next(): void;
  previous(): void;
}

export default (slideCount: unknown): AlpineComponent<State> => ({
  slideCount:
    typeof slideCount == "number" && slideCount > 0 ? slideCount : null,
  slide: 0,

  setSlide(slide) {
    if (this.slideCount !== null && slide >= 0 && slide < this.slideCount) {
      this.slide = slide;
    }
  },

  next() {
    if (this.slideCount !== null) {
      this.slide = this.slide === this.slideCount - 1 ? 0 : this.slide + 1;
    }
  },

  previous() {
    if (this.slideCount !== null) {
      this.slide = this.slide === 0 ? this.slideCount - 1 : this.slide - 1;
    }
  },
});
