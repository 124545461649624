export const equalMonth = (a: Date, b: Date) => {
  if (a.getUTCFullYear() !== b.getUTCFullYear()) {
    return false;
  }

  if (a.getUTCMonth() !== b.getUTCMonth()) {
    return false;
  }

  return true;
};

export const equalDate = (a: Date, b: Date) => {
  if (!equalMonth(a, b)) {
    return false;
  }

  if (a.getUTCDate() !== b.getUTCDate()) {
    return false;
  }

  return true;
};

export const equalTime = (a: Date, b: Date) => {
  if (!equalDate(a, b)) {
    return false;
  }

  if (a.getUTCHours() !== b.getUTCHours()) {
    return false;
  }

  if (a.getUTCMinutes() !== b.getUTCMinutes()) {
    return false;
  }

  if (a.getUTCSeconds() !== b.getUTCSeconds()) {
    return false;
  }

  return true;
};
