import { FunctionalComponent } from "preact";

import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ size: [false, "large"] }],
    ["bold"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const simpleFormatModules = {
  toolbar: [["bold"], ["clean"]],
};

const formats = ["size", "bold", "list", "bullet"];

interface QuillEditorProps {
  onChange: (value: string) => void;
  value: string;
  simpleFormatOptions?: boolean;
}

const QuillEditor: FunctionalComponent<QuillEditorProps> = ({
  onChange,
  value,
  simpleFormatOptions = false,
  children, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...props
}) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={simpleFormatOptions ? simpleFormatModules : modules}
      formats={formats}
      {...props}
    />
  );
};

export default QuillEditor;
