import { AlpineComponent } from "alpinejs";

export default (
  id: unknown,
): AlpineComponent<Record<string | symbol, unknown>> => {
  if (typeof id !== "string") {
    return {};
  }

  const j = document.createElement("script");

  j.async = true;
  j.defer = true;
  j.id = "hs-script-loader";
  j.src = `https://js-eu1.hs-scripts.com/${id}.js`;

  document.body.appendChild(j);

  return {};
};
