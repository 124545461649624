import Chart from "chart.js/auto";
import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  percentage: number;
}

export default (value: unknown): AlpineComponent<State> => ({
  percentage: typeof value === "number" ? value : 0,

  init() {
    if (this.$el instanceof HTMLCanvasElement) {
      new Chart(this.$el, {
        type: "doughnut",
        options: {
          circumference: 180,
          rotation: 270,
          aspectRatio: 2,
        },
        data: {
          datasets: [
            {
              label: "Cost / £",
              data: [
                Math.max(this.percentage, 0),
                100 - Math.max(this.percentage, 0),
              ],
              backgroundColor: ["rgba(120,190,53,0.79)", "rgb(234,226,226)"],
              borderWidth: 1,
              datalabels: {
                anchor: "end",
                align: "top",
              },
            },
          ],
        },
      });
    }
  },
});
