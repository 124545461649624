import { AlpineComponent } from "alpinejs";
import { z } from "zod";
import { call } from "../fetch";

const Status = z.union([
  z.literal("REQUEST"),
  z.literal("GRANTED"),
  z.literal("DENIED"),
]);

type Status = z.infer<typeof Status>;

interface State extends Record<string | symbol, unknown> {
  status: Status;
}

export default (data: unknown): AlpineComponent<State> => {
  const status = Status.default("REQUEST").parse(data);

  return {
    status,
    request() {
      return this.status === "REQUEST";
    },
    granted() {
      return this.status === "GRANTED";
    },
    denied() {
      return this.status === "DENIED";
    },
    async submit(event: Event) {
      event.preventDefault();

      if (!(event.target instanceof HTMLFormElement)) {
        return;
      }

      const body = new FormData(event.target);

      await call(event.target.action, {
        body,
        method: "POST",
        redirect: "manual",
      });

      if (body.get("consent") === "true") {
        this.status = "GRANTED";
      } else {
        this.status = "DENIED";
      }
    },
  };
};
