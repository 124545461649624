import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  selected: string | undefined;
}

export default (value: unknown): AlpineComponent<State> => ({
  selected: typeof value == "string" ? value : undefined,
  change(value: unknown) {
    if (typeof value === "string") {
      this.selected = value;
    }
  },
  active(value: unknown) {
    return this.selected === value;
  },
});
