import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  isVisible: boolean;
  duration: number;
}

export default (
  isVisible: unknown,
  duration: unknown,
): AlpineComponent<State> => ({
  isVisible: typeof isVisible == "boolean" ? isVisible : true,
  duration: typeof duration == "number" ? duration : 5000,

  init() {
    setTimeout(() => {
      this.isVisible = false;
    }, this.duration);
  },
});
