import { z } from "zod";

import { Result, call } from "../fetch";

const Suggestion = z.object({
  address: z.string(),
  url: z.string(),
  id: z.string(),
});

export type Suggestion = z.infer<typeof Suggestion>;

const AutocompleteResponse = z.object({
  suggestions: z.array(Suggestion),
});

export const autocomplete = async (
  query: string,
  key: string,
): Promise<Result<Suggestion[], string>> => {
  const term = encodeURIComponent(query);

  const response = await call(
    `https://api.getAddress.io/autocomplete/${term}?api-key=${key}`,
  );

  if (response.ok) {
    const result = AutocompleteResponse.safeParse(response.value);

    if (result.success) {
      return { ok: true, value: result.data.suggestions };
    }
  }

  return { ok: false, error: "Call to autocomplete failed." };
};

const GetAddressResponse = z
  .object({
    line_1: z.string(),
    line_2: z.string(),
    line_3: z.string(),
    line_4: z.string(),
    town_or_city: z.string(),
    postcode: z.string(),
  })
  .transform((data) => ({
    street: `${data.line_1} ${data.line_2} ${data.line_3} ${data.line_4}`,
    city: data.town_or_city,
    postcode: data.postcode,
  }));

export type Address = z.infer<typeof GetAddressResponse>;

export const getAddress = async (
  id: string,
  key: string,
): Promise<Result<Address, string>> => {
  const response = await call(
    `https://api.getAddress.io/get/${id}?api-key=${key}`,
  );

  if (response.ok) {
    const result = GetAddressResponse.safeParse(response.value);

    if (result.success) {
      return { ok: true, value: result.data };
    }
  }

  return { ok: false, error: "Call to getAddress failed." };
};
