import { AlpineComponent } from "alpinejs";

import { gtag } from "./ga";

interface State extends Record<string | symbol, unknown> {
  name: string;
}

export default (name: unknown, ...args: unknown[]): AlpineComponent<State> => {
  return {
    name: typeof name === "string" ? name : "unknown",
    send() {
      gtag("event", this.name, ...args);
    },
  };
};
