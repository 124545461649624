import { AlpineComponent } from "alpinejs";
import posthog from "posthog-js";

export default (
  key: unknown,
): AlpineComponent<Record<string | symbol, unknown>> => ({
  init: () => {
    if (!key || typeof key !== "string") return;
    posthog.init(key, { api_host: "https://eu.posthog.com" });
  },
});
