import { JSX } from "preact";
import { useState } from "preact/hooks";
import { useFormContext, UseFieldArrayRemove } from "react-hook-form";
import { FunctionalComponent } from "preact";

import { ICON_CLASSNAMES } from "../components/constants";
import { InputAdornment, Drawer, Tooltip, Button } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import {
  TextFieldInput,
  RichEditorInput,
  AutoCompleteComboBox,
} from "../components/inputs";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { EditPlanFormSchemaType } from "../schemas";
import BasicModal from "../components/modal";

interface EditMeasureFormProps {
  phaseIndex: number;
  measureIndex: number;
  remove: UseFieldArrayRemove;
  canDrag: boolean;
}

interface EditPhaseFormProps {
  phaseIndex: number;
  remove: UseFieldArrayRemove;
  onDrop: (item: {
    name: string;
    phaseIndex: number;
    measureIndex: number;
  }) => void;
}

interface NestedErrors {
  phases?: Array<{ measures?: Array<unknown> }>;
}

const EditMeasureForm: FunctionalComponent<EditMeasureFormProps> = ({
  phaseIndex,
  measureIndex,
  remove,
  canDrag,
}) => {
  const { getValues, formState } = useFormContext<EditPlanFormSchemaType>();
  const measureChoices = getValues("measure_options");

  const fieldName = `phases.${phaseIndex}.measures.${measureIndex}`;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [open, setOpen] = useState(
    Object.keys(getValues(`phases.${phaseIndex}.measures.${measureIndex}`))
      .length === 0,
  );

  const errorsExist: boolean =
    (formState.errors as NestedErrors)?.phases?.[phaseIndex]?.measures?.[
      measureIndex
    ] !== undefined;

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "measure",
      item: {
        name: fieldName,
        phaseIndex: phaseIndex,
        measureIndex: measureIndex,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      canDrag() {
        return canDrag;
      },
    }),
    [fieldName, "measure", canDrag],
  );

  const measureInformation = getValues(
    `phases.${phaseIndex}.measures.${measureIndex}.category`,
  );

  const measureName = getValues(
    `phases.${phaseIndex}.measures.${measureIndex}.name`,
  );

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={`m-2 flex flex-start border border-gray-200 bg-white rounded p-2 px-3 ${
        errorsExist ? "border-red-500" : "border-gray-200"
      }`}
    >
      <BasicModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <p className="text-gray-500 text-sm">
          Are you sure you wish to remove this measure?
        </p>
        <div className="flex justify-center items-center gap-x-4 mt-4">
          <Button variant="outlined" onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenDeleteModal(false);
              remove();
            }}
          >
            Delete
          </Button>
        </div>
      </BasicModal>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center">
          <div className="w-15 flex items-center justify-center p-2">
            <Tooltip
              title={
                !canDrag ? "You need to add another phase to drag measures" : ""
              }
              placement="bottom"
              arrow
            >
              <DragIndicatorIcon
                className={`${
                  canDrag ? "cursor-grab" : ""
                } text-gray-500 hover:text-indigo-500`}
              />
            </Tooltip>
          </div>
          <div className="flex-grow flex flex-col">
            <div className="flex">
              {errorsExist && <ErrorIcon color="error" className="mr-2" />}
              <div className="mr-2 text-gray-500">
                {measureInformation ? (
                  <div className="flex items-center justify-start">
                    <p className="bg-indigo-500 text-white p-1 px-2 rounded mr-3 shrink-0">
                      {measureInformation}
                    </p>
                    <p>{measureName}</p>
                  </div>
                ) : (
                  <span
                    className="cursor-pointer"
                    onClick={() => setOpen(true)}
                  >
                    Click to specifiy measure
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0">
          <span
            className="rounded-full outline outline-gray-200 text-black text-xs px-2 py-1 cursor-pointer hover:bg-blue-600 hover:text-white transition ease-in"
            onClick={() => setOpen(true)}
          >
            Edit
          </span>

          <DeleteIcon
            className={`${ICON_CLASSNAMES} ml-2`}
            onClick={() => setOpenDeleteModal(true)}
          />
        </div>
      </div>

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="p-5 max-w-xl">
          <div>
            <div className="flex items-center justify-between my-2">
              <h3 className="mr-2">Edit Measure</h3>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </div>
            <div className="grid grid-cols-1 gap-x-2">
              <div className="grid grid-cols-1">
                <AutoCompleteComboBox
                  options={measureChoices}
                  name={`${fieldName}.category`}
                  label="Measure Category"
                  size="small"
                />
                <p className="text-sm text-gray-500">
                  If you would like to display a more specific measure title to
                  the customer, please include this below.
                </p>
                <TextFieldInput
                  name={`${fieldName}.name`}
                  label="Measure Name (optional)"
                  size="small"
                />
                <TextFieldInput
                  name={`${fieldName}.plan_cost`}
                  label="Measure Cost (£)*"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
                <TextFieldInput
                  name={`${fieldName}.annual_cost_savings`}
                  label="Annual Saving (£)*"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
                <TextFieldInput
                  name={`${fieldName}.annual_carbon_savings`}
                  label="Annual CO2 Saving (if applicable)"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
                <TextFieldInput
                  name={`${fieldName}.energy_rating_improvement`}
                  label="EPC Score improvement (if applicable)"
                  size="small"
                />
              </div>
              <div className="h-full">
                <RichEditorInput
                  name={`${fieldName}.plan_notes`}
                  className="min-h-60"
                  placeholder="Please provide extensive details of why this measure is required and include reasoning in the context of the customer's property and priorities. Cover what this measure will achieve, how it will be installed, and any other relevant details."
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const EditPhaseForm: FunctionalComponent<EditPhaseFormProps> = ({
  phaseIndex,
  remove,
  onDrop,
}) => {
  const [expandDescription, setExpandDescription] = useState(false);
  const { register } = useFormContext<EditPlanFormSchemaType>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { max, min, ...inputProps } = register(`phases.${phaseIndex}.name`);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "measure",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop} className="relative">
      <BasicModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <p className="text-gray-500 text-sm">
          Deleting this phase will remove ALL measures within it.
        </p>
        <p className="text-gray-500 text-sm">
          Are you sure you wish to remove this phase?
        </p>
        <div className="flex justify-center items-center gap-x-4 mt-4">
          <Button variant="outlined" onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenDeleteModal(false);
              remove();
            }}
          >
            Delete
          </Button>
        </div>
      </BasicModal>
      {isOver && canDrop && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-indigo-500 opacity-25"></div>
      )}
      <div className="bg-slate-900 text-white p-2 pl-5 items-center flex justify-between w-full rounded-t-lg">
        <div className="flex items-center gap-x-3 w-4/5">
          <input
            className="text-white text-xs p-1 px-2 rounded-lg w-1/2 bg-slate-700 border-none border-slate-700"
            max={max?.toString()}
            min={min?.toString()}
            {...inputProps}
            placeholder="Phase Name (Optional)"
          />
        </div>

        <div>
          <DeleteIcon
            className={`${ICON_CLASSNAMES} ml-2`}
            onClick={() => setOpenDeleteModal(true)}
          />
        </div>
      </div>
      <div className="bg-gray-100 border-x border-gray-200 p-2">
        <div className="relative">
          <TextFieldInput
            name={`phases.${phaseIndex}.description`}
            label="Phase Description"
            multiline
            minRows={expandDescription ? 6 : 1}
            rows={expandDescription ? 6 : 1}
            onKeyPress={(
              e: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
            ) => {
              if (e.key === "Enter" && !expandDescription) {
                setExpandDescription(true);
              }
            }}
            size="small"
          />
          <span
            className=" rounded-full text-gray-500 hover:text-gray-400 text-xs cursor-pointer transition ease-in absolute top-2 right-2 bg-gray-100 z-10 px-2"
            onClick={() => setExpandDescription(!expandDescription)}
          >
            {expandDescription ? (
              <span>
                {" "}
                Collapse <KeyboardArrowUpIcon />
              </span>
            ) : (
              <span>
                {" "}
                Expand <KeyboardArrowDownIcon />
              </span>
            )}
          </span>
        </div>
      </div>
      <MeasureList phaseIndex={phaseIndex} />
    </div>
  );
};

const MeasureList: FunctionalComponent<{ phaseIndex: number }> = ({
  phaseIndex,
}) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.measures`,
  });

  return (
    <div className="rounded bg-gray-100 border-x border-b border-gray-200 p-2">
      {fields.map((_, index) => {
        return (
          <EditMeasureForm
            phaseIndex={phaseIndex}
            measureIndex={index}
            remove={() => remove(index)}
            canDrag={(getValues("phases") as Array<unknown>).length > 1}
          />
        );
      })}
      <div
        className="m-2 cursor-pointer transition duration-150 hover:text-indigo-600 text-indigo-500 flex bg-white border border-indigo-200 hover:border-indigo-400 rounded-lg p-2"
        onClick={() => append({})}
      >
        <AddCircleIcon />
        <p className="ml-3">Add Measure</p>
      </div>
    </div>
  );
};

const PhaseForm: FunctionalComponent = () => {
  const { control, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "phases",
  });

  const handleDrop = (
    index: number,
    item: { name: string; phaseIndex: number; measureIndex: number },
  ) => {
    if (item.phaseIndex === index) return;
    const droppedMeasure: unknown = getValues(item.name);
    const existingMeasures = getValues(
      `phases.${index}.measures`,
    ) as Array<unknown>;
    setValue(`phases.${index}.measures`, [...existingMeasures, droppedMeasure]);
    const remainingMeasures = getValues(
      `phases.${item.phaseIndex}.measures`,
    ) as Array<unknown>;
    remainingMeasures.splice(item.measureIndex, 1);
    setValue(`phases.${item.phaseIndex}.measures`, remainingMeasures);
  };

  return (
    <div className="mx-2">
      <p className="text-gray-500 text-lg">Phases and measures</p>
      <p className="text-gray-500 text-sm">
        Please add the various phases and measures contained within each phase.
        For each measure and phase, please provide details of why this is
        required and include reasoning in the context of the customer's property
        and priorities.
      </p>
      <DndProvider backend={HTML5Backend}>
        {fields.map((field, index) => {
          return (
            <div className="my-2" key={field.id}>
              <EditPhaseForm
                phaseIndex={index}
                remove={() => remove(index)}
                onDrop={(item) => handleDrop(index, item)}
              />
            </div>
          );
        })}
      </DndProvider>
      <div
        className="my-2 mt-4 cursor-pointer transition duration-150 hover:text-gray-600 text-gray-500 flex bg-gray-100 border border-gray-200 hover:border-gray-400 rounded-lg p-3"
        onClick={() =>
          append({
            name: `Phase ${fields.length + 1}`,
          })
        }
      >
        <AddCircleIcon />
        <p className="ml-3">Add Phase</p>
      </div>
    </div>
  );
};

export default PhaseForm;
