import { render, FunctionalComponent } from "preact";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  EditPlanFormSchema,
  PlanFormSchema,
  ServerValidationErrors,
  PhaseSchema,
  MeasureSchema,
} from "../schemas";
import { call } from "../../fetch";
import {
  TextFieldInput,
  QuestionLabel,
  NumberFieldInput,
  CheckboxInput,
  RichEditorInput,
} from "../components/inputs";
import PhaseForm from "./phase-form";
import {
  FileUploadComponent,
  FormFileTableComponent,
} from "../components/file-upload";
import {
  FormContainer,
  FormInstructionsContainer,
} from "../components/containers";
import {
  InputAdornment,
  Fab,
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  Snackbar,
  Fade,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";

interface Props {
  csrf: string;
  plan: string | undefined;
}

const generateWarningMessage = (errorName: string, value: string) => {
  if (errorName.includes("phases")) {
    return "Please review the phase and measure details";
  }
  if (errorName.includes("next_steps")) {
    return "Please review the next steps details";
  }
  return `${errorName} - ${value}`;
};

const EditPlanForm: FunctionalComponent<Props> = ({ csrf, plan = "" }) => {
  const endpoint = window.location.href;

  const data = PlanFormSchema.parse(JSON.parse(atob(plan)));

  const methods = useForm({
    resolver: zodResolver(EditPlanFormSchema),
    defaultValues: data,
  });

  const onSubmit = async (data: object) => {
    const response = await call(endpoint, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
    });
    if (response.ok) {
      const result = EditPlanFormSchema.safeParse(response.value);
      if (result.success) {
        methods.reset(data);
      } else {
        const errors = ServerValidationErrors.safeParse(response.value);
        if (errors.success) {
          for (const [field, value] of Object.entries(errors.data)) {
            if (field === "non_field_errors" && value?.length) {
              methods.setError("root.serverError", {
                type: "server",
                message: value.join(", "),
              });
            } else if (field === "cover_image_file") {
              methods.setError("cover_image_file", {
                type: "string",
                message: "Image upload failed",
              });
            } else if (field === "phases" && value?.length) {
              errors.data.phases?.forEach((phaseError, phaseIndex) => {
                for (const [field, value] of Object.entries(phaseError)) {
                  if (field === "measures" && phaseError.measures) {
                    phaseError.measures.forEach(
                      (measureError, measureIndex) => {
                        for (const [field, value] of Object.entries(
                          measureError,
                        )) {
                          if (Array.isArray(value)) {
                            const errorField =
                              MeasureSchema.keyof().parse(field);
                            methods.setError(
                              `phases.${phaseIndex}.measures.${measureIndex}.${errorField}`,
                              {
                                type: "string",
                                message: value
                                  ? value.join(", ")
                                  : "Unknown error",
                              },
                            );
                          }
                        }
                      },
                    );
                  } else {
                    const errorField = PhaseSchema.keyof().parse(field);
                    methods.setError(`phases.${phaseIndex}.${errorField}`, {
                      type: "string",
                      message: value ? value.join(", ") : "Unknown error",
                    });
                  }
                }
              });
            } else {
              const errorField = EditPlanFormSchema.keyof().parse(field);
              methods.setError(errorField, {
                type: "string",
                message: value ? value.join(", ") : "Unknown error",
              });
            }
          }
        } else {
          methods.setError("root.serverError", { type: "custom" });
        }
      }
    } else {
      methods.setError("root", { type: "custom" });
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="POST" onSubmit={methods.handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1000 }}
          open={methods.formState.isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={Object.keys(methods.formState.errors).length > 0}
        >
          <Alert variant="filled" severity="warning">
            <AlertTitle>Warning</AlertTitle>
            <p>Please check form errors</p>
            <ul>
              {Object.entries(methods.formState.errors).map(([key, value]) => (
                <li key={key}>
                  {generateWarningMessage(key, value.message ?? "Error")}
                </li>
              ))}
            </ul>
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={methods.formState.isSubmitSuccessful}
          autoHideDuration={2000}
          onClose={() => methods.reset(undefined, { keepValues: true })}
        >
          <Alert variant="filled" severity="success">
            <AlertTitle>Plan Saved.</AlertTitle>
            You may preview the plan or continue editing.
          </Alert>
        </Snackbar>
        {methods.formState.isDirty && !methods.formState.isSubmitSuccessful && (
          <div className="fixed bottom-10 z-10 left-0 right-0 flex justify-center">
            <Alert className="w-1/2" severity="info">
              You have unsaved changes
            </Alert>
          </div>
        )}

        <div className="p-2 flex justify-between items-center sticky top-[90px] right-0 z-10 bg-slate-900">
          <div className="flex justify-start items-center gap-4">
            <Fab
              variant="extended"
              className="sticky"
              size="small"
              disabled={
                methods.formState.isSubmitting || methods.formState.isDirty
              }
            >
              <a href={methods.getValues("dashboard_url") ?? ""}>
                <p className="p-2">Back to dashboard</p>
              </a>
            </Fab>
            <p className="text-white">{methods.getValues("address")}</p>
          </div>
          <div className="flex justify-end gap-2">
            <Fab
              variant="extended"
              size="small"
              className="sticky px-3"
              type="submit"
              disabled={
                methods.formState.isSubmitting || !methods.formState.isDirty
              }
            >
              <p className="p-2">
                <NavigationIcon /> Save
              </p>
            </Fab>
            {!methods.formState.isDirty && (
              <Fab
                variant="extended"
                size="small"
                className="sticky px-3"
                onClick={(event: Event) => {
                  event.preventDefault();
                  window.open(methods.getValues("url"), "_blank");
                }}
                disabled={
                  methods.formState.isSubmitting || methods.formState.isDirty
                }
              >
                <p className="p-2">
                  <NavigationIcon /> Preview
                </p>
              </Fab>
            )}

            {!methods.formState.isDirty &&
              methods.getValues("approval_url") && (
                <form
                  action={methods.getValues("approval_url") ?? ""}
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="csrfmiddlewaretoken"
                    value={csrf}
                  />
                  <Fab
                    variant="extended"
                    className="sticky"
                    size="small"
                    type="submit"
                    disabled={
                      methods.formState.isSubmitting ||
                      methods.formState.isDirty
                    }
                  >
                    <p className="p-2">Submit for approval</p>
                  </Fab>
                </form>
              )}
          </div>
        </div>

        <Fade in={true}>
          <div className="w-4/5">
            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <QuestionLabel>
                    What are the before and after EPC score changes?
                  </QuestionLabel>
                  <div className="grid grid-cols-2 gap-x-2">
                    <NumberFieldInput
                      name="home_epc_score"
                      label="Current EPC Score"
                      size="small"
                    />
                    <NumberFieldInput
                      name="home_epc_score_with_plan"
                      label="New EPC Score"
                      size="small"
                    />
                  </div>
                  <QuestionLabel>
                    What are the before and after Annual Bill changes?
                  </QuestionLabel>
                  <div className="grid grid-cols-2 gap-x-2">
                    <TextFieldInput
                      name="home_average_annual_bills"
                      label="Current Cost"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                    />
                    <TextFieldInput
                      name="home_average_annual_bills_with_plan"
                      label="New Cost"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <QuestionLabel>
                    What are the current Annual CO2 Emissions?
                  </QuestionLabel>
                  <div className="grid grid-cols-2 gap-x-2">
                    <TextFieldInput
                      name="home_annual_co2"
                      label="Current Annual CO2 Emissions"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <QuestionLabel>
                    Please state the specific priorities which the customer has
                    mentioned
                  </QuestionLabel>
                  <p className="text-fn-blue text-xs">
                    Separate text by a new line to create another content box on
                    the plan.
                    <strong className="text-fn-green"> Bold text</strong> will
                    turn it <strong className="text-fn-green">green</strong> on
                    your plan.
                  </p>
                  <RichEditorInput
                    name="customer_priorities_text"
                    simpleFormatOptions
                  />
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <QuestionLabel>
                    Please specify the modelling assumptions for this property.
                    Include all relevant details such as the property type,
                    size, and condition.
                  </QuestionLabel>
                  <p className="text-fn-blue text-xs">
                    Separate text by a new line to create another content box on
                    the plan.
                    <strong className="text-fn-green"> Bold text</strong> will
                    turn it <strong className="text-fn-green">green</strong> on
                    your plan.
                  </p>
                  <RichEditorInput
                    name="modelling_assumptions_text"
                    simpleFormatOptions
                  />
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <QuestionLabel>
                    Are there any pre-work requirements the client will need to
                    consider for any of the measures proposed?
                  </QuestionLabel>
                  <div className="grid grid-cols-5 my-2 gap-y-2 items-center">
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        label="Damp Survey"
                        name="requires_damp_survey"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_damp_survey") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="damp_survey_description"
                        readOnly={
                          methods.watch("requires_damp_survey") === false
                        }
                        simpleFormatOptions
                      />
                    </div>

                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        label="Boroscope Survey"
                        name="requires_boroscope_survey"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_boroscope_survey") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="boroscope_survey_description"
                        readOnly={
                          methods.watch("requires_boroscope_survey") === false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_air_tightness_testing"
                        label="Air Tightness Testing"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_air_tightness_testing") ===
                        false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="air_tightness_testing_description"
                        readOnly={
                          methods.watch("requires_air_tightness_testing") ===
                          false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_planning_permission"
                        label="Planning Permission"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_planning_permission") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="planning_permission_description"
                        readOnly={
                          methods.watch("requires_planning_permission") ===
                          false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_party_wall_survey"
                        label="Party Wall Award"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_party_wall_survey") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="party_wall_survey_description"
                        readOnly={
                          methods.watch("requires_party_wall_survey") === false
                        }
                      />
                      simpleFormatOptions
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_listed_building_consent"
                        label="Listed Building Consent"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_listed_building_consent") ===
                        false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="listed_building_consent_description"
                        readOnly={
                          methods.watch("requires_listed_building_consent") ===
                          false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_thermal_imaging"
                        label="Thermal Imaging"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_thermal_imaging") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="thermal_imaging_description"
                        readOnly={
                          methods.watch("requires_thermal_imaging") === false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="requires_asbestos_survey"
                        label="Asbestos Survey"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("requires_asbestos_survey") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="asbestos_survey_description"
                        readOnly={
                          methods.watch("requires_asbestos_survey") === false
                        }
                        simpleFormatOptions
                      />
                    </div>
                    <div className="col-span-2">
                      <CheckboxInput
                        control={methods.control}
                        name="other_requirements"
                        label="Other works"
                      />
                    </div>
                    <div
                      className={`col-span-3 ${
                        methods.watch("other_requirements") === false
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <RichEditorInput
                        name="other_requirements_description"
                        readOnly={methods.watch("other_requirements") === false}
                        simpleFormatOptions
                      />
                    </div>
                  </div>
                </FormContainer>
              </div>
            </div>
            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <PhaseForm />
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer>
                  <p className="">Plan Documents Upload</p>
                  <QuestionLabel>
                    Please upload all documents that have been generated for
                    this project. Uploads may include medium term improvement
                    plans, condition reports, energy reports, floor plans, XML.
                    Please include all that you have available.
                  </QuestionLabel>
                  <div>
                    <FormFileTableComponent csrf={csrf} />
                  </div>
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer className="shadow p-6 m-3 border-2 border-indigo-500">
                  <p className="text-indigo-500 text-center">
                    To be completed by Furbnow staff only
                  </p>
                  <QuestionLabel>
                    Please include details of the expected change in value of
                    the customers property after all measures have been
                    installed and snagging completed?
                  </QuestionLabel>
                  <div className="grid grid-cols-2 gap-x-2">
                    <NumberFieldInput
                      name="home_value_current"
                      label="Current Valuation"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                    />
                    <NumberFieldInput
                      name="home_value_with_plan"
                      label="New valuation with plan"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </FormContainer>
              </div>
            </div>

            <div className="grid grid-cols-10 w-full">
              <FormInstructionsContainer />
              <div className="col-span-7">
                <FormContainer className="shadow p-6 m-3 border-2 border-indigo-500">
                  <p className="text-indigo-500 text-center">
                    To be completed by Furbnow staff only
                  </p>
                  <QuestionLabel>
                    Please upload an optional image of the property to be used
                    as the cover image for the plan. This is highly recommended
                    and please ensure the image is of high quality.
                  </QuestionLabel>
                  <div>
                    <FileUploadComponent
                      control={methods.control}
                      name="cover_image_file"
                    />
                  </div>
                </FormContainer>
              </div>
            </div>
          </div>
        </Fade>
      </form>
    </FormProvider>
  );
};

const root = document.getElementById("edit-plan-form");

const csrf = document.getElementById("csrfmiddlewaretoken");

if (root && csrf instanceof HTMLInputElement) {
  render(<EditPlanForm csrf={csrf.value} plan={root.dataset["plan"]} />, root);
}
