import { AlpineComponent } from "alpinejs";
import { setupSdk } from "@matterport/sdk";

export default (
  key: unknown,
  space: unknown,
): AlpineComponent<Record<string | symbol, unknown>> => ({
  async init() {
    if (typeof key !== "string" || typeof space !== "string") {
      return;
    }

    await setupSdk(key, {
      container: this.$el,
      space: space,
    });
  },
});
