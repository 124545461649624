import { AlpineComponent } from "alpinejs";

export default (): AlpineComponent<Record<string | symbol, unknown>> => ({
  click() {
    this.$root.querySelectorAll("input[type='checkbox']").forEach((x) => {
      if (
        this.$el instanceof HTMLInputElement &&
        x instanceof HTMLInputElement
      ) {
        x.checked = this.$el.checked;
      }
    });
  },
});
