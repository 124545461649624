import { z } from "zod";

export const UploadedFileSchema = z.union([
  z.object({
    labels: z.string().array(),
    url: z.string(),
  }),
  z.object({
    labels: z.string().array(),
    file: z.object({
      content: z.string(),
      filename: z.string(),
      "content-type": z.string(),
    }),
  }),
  z.null(),
]);

export const ProjectFileSchema = z.object({
  pk: z.number(),
  filename: z.string(),
  labels: z.string().array(),
  url: z.string(),
  destroy: z.boolean(),
  user: z.string(),
  created: z.string(),
});

export type UploadedFileSchema = z.infer<typeof UploadedFileSchema>;

export type ProjectFileSchemaType = z.infer<typeof ProjectFileSchema>;

export const BaseMeasureSchema = z.object({
  pk: z.number().int().min(1),
  name: z.string().min(1),
});

export const MeasureSchema = z.object({
  pk: z.number().optional(),
  category: z.string(),
  plan_notes: z.string().optional(),
  plan_cost: z.coerce.number().multipleOf(0.01),
  annual_cost_savings: z.coerce.number().multipleOf(0.01),
  annual_carbon_savings: z.coerce.number().multipleOf(0.01).optional(),
  energy_rating_improvement: z.coerce.number().multipleOf(0.01).optional(),
  name: z.string().max(50).optional(),
  favourite: z.boolean().default(false),
});

export const PhaseSchema = z.object({
  pk: z.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  measures: z.array(MeasureSchema),
});

export const PlanFormSchema = z.object({
  cover_image_file: UploadedFileSchema,
  project_files: z.array(ProjectFileSchema),
  home_epc_score: z.number().int(),
  home_epc_score_with_plan: z.number().int(),
  home_annual_co2: z.coerce.number().multipleOf(0.01),
  home_average_annual_bills: z.coerce.number().multipleOf(0.01),
  home_average_annual_bills_with_plan: z.coerce.number().multipleOf(0.01),
  customer_priorities_text: z.string(),
  modelling_assumptions_text: z.string(),
  other_requirements: z.boolean(),
  other_requirements_description: z.string().optional(),
  phases: z.array(PhaseSchema),
  home_value_current: z.coerce.number().int(),
  home_value_with_plan: z.coerce.number().int(),
  requires_damp_survey: z.boolean(),
  damp_survey_description: z.string().optional(),
  requires_boroscope_survey: z.boolean(),
  boroscope_survey_description: z.string().optional(),
  requires_air_tightness_testing: z.boolean(),
  air_tightness_testing_description: z.string().optional(),
  requires_planning_permission: z.boolean(),
  planning_permission_description: z.string().optional(),
  requires_party_wall_survey: z.boolean(),
  party_wall_survey_description: z.string().optional(),
  requires_listed_building_consent: z.boolean(),
  listed_building_consent_description: z.string().optional(),
  requires_thermal_imaging: z.boolean(),
  thermal_imaging_description: z.string().optional(),
  requires_asbestos_survey: z.boolean(),
  asbestos_survey_description: z.string().optional(),
  measure_options: z.array(z.string()),
  url: z.string(),
  approval_url: z.string().nullable(),
  dashboard_url: z.string(),
  address: z.string(),
  file_url: z.string(),
});

export const EditPlanFormSchema = PlanFormSchema.extend({
  customer_priorities_text: z.string().min(1),
  modelling_assumptions_text: z.string().min(1),
});

export type EditPlanFormSchemaType = z.infer<typeof EditPlanFormSchema>;

export const ServerValidationErrors = z
  .object({
    non_field_errors: z.array(z.string()),
    cover_image_file: z.array(z.string()),
    home_epc_score: z.array(z.string()),
    home_epc_score_with_plan: z.array(z.string()),
    home_annual_co2: z.array(z.string()),
    home_average_annual_bills: z.array(z.string()),
    home_average_annual_bills_with_plan: z.array(z.string()),
    customer_priorities_text: z.array(z.string()),
    modelling_assumptions_text: z.array(z.string()),
    other_requirements: z.array(z.string()),
    other_requirements_description: z.array(z.string()),
    phases: z.array(
      z.object({
        non_field_errors: z.array(z.string()),
        pk: z.array(z.string()),
        name: z.array(z.string()),
        description: z.array(z.string()),
        measures: z.array(
          z.object({
            non_field_errors: z.array(z.string()),
            pk: z.array(z.string()),
            category: z.array(z.string()),
            plan_notes: z.array(z.string()),
            plan_cost: z.array(z.string()),
            annual_cost_savings: z.array(z.string()),
            annual_carbon_savings: z.array(z.string()),
            energy_rating_improvement: z.array(z.string()),
            name: z.array(z.string()),
          }),
        ),
      }),
    ),
    home_value_current: z.array(z.string()),
    home_value_with_plan: z.array(z.string()),
    requires_damp_survey: z.array(z.string()),
    damp_survey_description: z.array(z.string()),
    requires_boroscope_survey: z.array(z.string()),
    boroscope_survey_description: z.array(z.string()),
    requires_air_tightness_testing: z.array(z.string()),
    air_tightness_testing_description: z.array(z.string()),
    requires_planning_permission: z.array(z.string()),
    planning_permission_description: z.array(z.string()),
    requires_party_wall_survey: z.array(z.string()),
    party_wall_survey_description: z.array(z.string()),
    requires_thermal_imaging: z.array(z.string()),
    thermal_imaging_description: z.array(z.string()),
    requires_asbestos_survey: z.array(z.string()),
    asbestos_survey_description: z.array(z.string()),
    measure_options: z.array(
      z.object({
        pk: z.array(z.string()),
        name: z.array(z.string()),
      }),
    ),
    url: z.array(z.string()),
    approval_url: z.array(z.string()),
    dashboard_url: z.array(z.string()),
    address: z.array(z.string()),
    file_url: z.array(z.string()),
  })
  .deepPartial();
