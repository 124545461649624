import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  hasNext: boolean;
  hasPrev: boolean;
  next(): void;
  onResize(): void;
  prev(): void;
}

export default (): AlpineComponent<State> => ({
  hasNext: true,

  hasPrev: true,

  init() {
    const container = this.$refs["container"];

    if (container === undefined) {
      return;
    }

    this.hasNext =
      container.scrollLeft < container.scrollWidth - container.clientWidth;

    this.hasPrev = container.scrollLeft > 0;

    container.addEventListener("scroll", () => {
      this.hasNext =
        container.scrollLeft < container.scrollWidth - container.clientWidth;

      this.hasPrev = container.scrollLeft > 0;
    });

    window.addEventListener("resize", this.onResize.bind(this));

    this.onResize();
  },

  next() {
    const container = this.$refs["container"];

    if (container === undefined) {
      return;
    }

    const element = Array.from(container.children)
      .slice(0, -1)
      .filter((x: Element): x is HTMLElement => x instanceof HTMLElement)
      .filter(({ offsetLeft }) => offsetLeft > container.scrollLeft)
      .at(0);

    if (element === undefined) {
      return;
    }

    container.scrollTo({
      left: element.offsetLeft,
      behavior: "smooth",
    });
  },

  onResize() {
    const container = this.$refs["container"];

    if (container === undefined) {
      return;
    }

    const padding = this.$refs["padding"];

    if (padding === undefined) {
      return;
    }

    const last = Array.from(container.children).at(-2)?.clientWidth ?? 0;

    padding.style.width = `${container.clientWidth - last - 48}px`;
  },

  prev() {
    const container = this.$refs["container"];

    if (container === undefined) {
      return;
    }

    const element = Array.from(container.children)
      .slice(0, -1)
      .filter((x: Element): x is HTMLElement => x instanceof HTMLElement)
      .filter(({ offsetLeft }) => offsetLeft < container.scrollLeft)
      .at(-1);

    if (element === undefined) {
      return;
    }

    container.scrollTo({
      left: element.offsetLeft,
      behavior: "smooth",
    });
  },
});
